import React, { useEffect } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { Responsive, WidthProvider } from 'react-grid-layout'
import { useSelector, useDispatch } from 'react-redux'
// import 'react-grid-layout/css/styles.css'
// import 'react-resizable/css/styles.css'
import '../../../../config/css/react-draggable.css'

import appStateQuery from '../../../../api/query/appState'
import AppText from '../../../../components/Text'
import { MARGIN, PADDING, ROW_HEIGHT, generateLayouts } from './utils'
import {
  selectWidgetLayout,
  selectWidgets,
  setWidgets,
} from '../../../../redux/slices/dashboard'
import DisplayWidgetWithSize from './DisplayWidget'
import { QUERY_KEYS } from '../../../../config/queryKeys'
import { STATE_KEY } from '../../../../config/constants'

const ResponsiveReactGridLayout = WidthProvider(Responsive)

interface IWidgetLayout {
  readonly editing: boolean
}

export const WidgetGrid: React.FC<IWidgetLayout> = ({ editing }) => {
  // load app state
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const dashboardState = appStateQuery.getAppState(STATE_KEY.dashboardLayout)
  const widgetLayout = useSelector(selectWidgetLayout)
  const widgets = useSelector(selectWidgets)

  const stateMutation = useMutation(appStateQuery.postAppState, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.appState.all)
      queryClient.invalidateQueries([
        QUERY_KEYS.appState.item(STATE_KEY.dashboardLayout),
      ])
    },
    onError: (error) => {
      // alert('Failed update dashboard state')
      console.log(error)
    },
  })

  useEffect(() => {
    if (
      dashboardState.isLoading ||
      !widgets ||
      widgets === dashboardState.data?.stateValue
    ) {
      return
    }

    // Save when
    if (!editing) {
      stateMutation.mutate({
        stateKey: STATE_KEY.dashboardLayout,
        stateValue: widgets,
      })
    }
  }, [editing])

  useEffect(() => {
    if (dashboardState.data?.stateValue) {
      handleLayoutChange(dashboardState.data?.stateValue)
    }
  }, [dashboardState.data?.stateValue])

  function handleLayoutChange(layout) {
    const newLayouts = generateLayouts(layout)

    dispatch(setWidgets(newLayouts.lg))
  }

  function handleWidthChange() {
    const newLayouts = generateLayouts(widgets)

    dispatch(setWidgets(newLayouts.lg))
  }

  if (dashboardState.isLoading) {
    return <AppText>Loading...</AppText>
  }

  const children = widgets
    .map((item) => {
      const widgetType = item.i.split('-')[0]

      if (widgetType === 'welcome' || widgetType === 'today') {
        return null
      }

      return (
        <div key={item.i} data-grid={item}>
          <DisplayWidgetWithSize
            onSize={(size) => {
              handleWidthChange()
            }}
            key={item.i}
            item={item}
            editing={editing}
            widgetType={widgetType}
            widgetParams={{
              editing,
              onLoaded: (widgetName: string) => {
                // console.log('widget finished loading: ' + widgetName)
                // handleWidthChange();
                // handleLayoutChange(widgetArray)
              },
            }}
          />
        </div>
      )
    })
    .filter((child): child is React.ReactElement => child !== null)

  return (
    <ResponsiveReactGridLayout
      onLayoutChange={handleLayoutChange}
      onWidthChange={handleWidthChange}
      // verticalCompact={null}
      layout={widgetLayout}
      breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
      preventCollision={false}
      cols={{ lg: 1, md: 1, sm: 1, xs: 1, xxs: 1 }}
      margin={[MARGIN, MARGIN]}
      containerPadding={[PADDING, PADDING]}
      isDraggable={editing}
      isResizable={false}
      measureBeforeMount={false}
      // items={5}
      rowHeight={ROW_HEIGHT}
    >
      {children}
    </ResponsiveReactGridLayout>
  )
}
