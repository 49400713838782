import React from 'react'
import { StyleSheet, View } from 'react-native'

import ActivityIndicator from '../../../components/ActivityIndicator'
import trophyCabinetQuery from '../../../api/query/trophyCabinet'
import Screen from '../../../components/Screen'
import Button from '../../../components/Button'
import AppText from '../../../components/Text'
import colors from '../../../config/colors'
import { defaultStyles } from '../../../config/styles'
import ButtonIcon from '../../../components/ButtonIcon'
import useAuth from '../../../auth/useAuth'
import TitleGraphic from '../../../components/TitleGraphic'
import TrophyShelf from '../../../components/trophy/TrophyShelf'
import TrophyButton from '../../../components/trophy/TrophyButton'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { AppStackParamList } from '../../../navigation/AppNavigator'
import { useNavigation } from '@react-navigation/native'

function ListScreen() {
  const navigation = useNavigation<DrawerNavigationProp<AppStackParamList>>()
  const { user } = useAuth()
  const { isLoading, isSuccess, isError, data, refetch } =
    trophyCabinetQuery.getTrophies(user.id)

  return (
    <Screen style={defaultStyles.screen}>
      <TitleGraphic
        section="Trophy Cabinet"
        title="My Trophies"
        graphic={require('/assets/lead-trophy.jpg')}
      />
      <View style={defaultStyles.mainContainer}>
        <ButtonIcon
          text="Add a new trophy"
          icon="text-box-plus-outline"
          color={colors.white}
          backgroundColor={colors.primary}
          onPress={() => {
            navigation.navigate('TROPHY_CABINET', {
              screen: 'Trophy_Form',
              params: {
                trophyId: null,
                shedAction: false,
              },
            })
          }}
        />
        <ActivityIndicator visible={isLoading} />
        {isError && (
          <>
            <AppText>Couldn't retrieve your Trophy Cabinet.</AppText>
            <Button title="Retry" onPress={() => refetch()} />
          </>
        )}
        {isSuccess && data.length > 0 && (
          <View style={styles.shelfStyle}>
            {data.map((trophy) => {
              return (
                <TrophyButton
                  key={trophy.id}
                  onPress={() => {
                    navigation.navigate('TROPHY_CABINET', {
                      screen: 'Trophy_View',
                      params: {
                        trophyId: trophy.id,
                      },
                    })
                  }}
                >
                  <TrophyShelf trophy={trophy} />
                </TrophyButton>
              )
            })}
          </View>
        )}
        {isSuccess && data.length === 0 && (
          <AppText>There are currently no Trophies in your Cabinet.</AppText>
        )}
      </View>
    </Screen>
  )
}

const styles = StyleSheet.create({
  shelfStyle: {
    alignContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    width: '100%',
  },
})

export default ListScreen
