import React, { useEffect, useState } from 'react'
import {
  DrawerNavigationOptions,
  createDrawerNavigator,
} from '@react-navigation/drawer'

import CustomSidebar from '../components/CustomSidebar'

import colors from '../config/colors'
import { NavigatorScreenParams } from '@react-navigation/native'
import { BoomParamList } from './BoomParamList'
import { BossParamList } from './BossParamList'
import { useWindowDimensions } from 'react-native'
import { getDrawerType } from '../components/part/helper'
import { IRoute, getRoutes } from '../config/routes'
import LoadingComponent from '../components/LoadingComponent'
import { CriticalImpactOpportunityParamList } from './CriticalImpactOpportunityParamList'
import { DashboardParamList } from './DashboardParamList'
import { HelpParamList } from './HelpParamList'
import { ProfileParamList } from './ProfileParamList'
import { RapidReviewParamList } from './RapidReviewParamList'
import { ReminderParamList } from './ReminderParamList'
import { ResourceParamList } from './ResourceParamList'
import { SharedWithMeParamList } from './SharedWithMeParamList'
import { TeamParamList } from './TeamParamList'
import { TrophyCabinetParamList } from './TrophyCabinetParamList'

export type AppStackParamList = {
  BOOM: NavigatorScreenParams<BoomParamList>
  BOSS: NavigatorScreenParams<BossParamList>
  CRITICAL_IMPACT_OPPORTUNITY: NavigatorScreenParams<CriticalImpactOpportunityParamList>
  DASHBOARD: NavigatorScreenParams<DashboardParamList>
  HELP: NavigatorScreenParams<HelpParamList>
  PROFILE: NavigatorScreenParams<ProfileParamList>
  RAPID_REVIEW: NavigatorScreenParams<RapidReviewParamList>
  REMINDER: NavigatorScreenParams<ReminderParamList>
  RESOURCE: NavigatorScreenParams<ResourceParamList>
  SHARED_WITH_ME: NavigatorScreenParams<SharedWithMeParamList>
  TEAM: NavigatorScreenParams<TeamParamList>
  TROPHY_CABINET: NavigatorScreenParams<TrophyCabinetParamList>
}

interface AppDrawerNavigationOptions extends DrawerNavigationOptions {
  groupName?: string
}

const AppNavigator = () => {
  // https://github.com/craig1123/react-recipes/blob/master/docs/useNotification.md
  // useNotifications();

  const [routes, setRoutes] = useState<IRoute[]>([]) // Initialize state
  const [loading, setLoading] = useState(true) // Loading state

  const Drawer = createDrawerNavigator()
  const dimensions = useWindowDimensions()
  const drawerType = getDrawerType(dimensions)

  useEffect(() => {
    const fetchRoutes = async () => {
      const fetchedRoutes = await getRoutes()
      setRoutes(fetchedRoutes)
      setLoading(false)
    }

    fetchRoutes()
  }, [])

  if (loading) {
    return <LoadingComponent />
  }

  return (
    <Drawer.Navigator
      drawerContent={CustomSidebar}
      useLegacyImplementation={false}
      screenOptions={{
        drawerType: drawerType,
        headerShown: false,
      }}
    >
      {routes.map((route) => (
        <Drawer.Screen
          name={route.name}
          key={route.name}
          options={
            {
              drawerLabel: route.label,
              groupName: route.groupName,
              activeTintColor: colors.white,
              activeBackgroundColor: colors.light,
              // inactiveTintColor: 'black',
              // inactiveBackgroundColor: 'white',
              visible: route.visible,
              enabled: route.enabled,
              defaultRoute: route.routeName,
            } as AppDrawerNavigationOptions
          }
          component={route.routeStack}
        />
      ))}
    </Drawer.Navigator>
  )
}

export default AppNavigator
