import React from 'react'
import { View } from 'react-native'
import { useFocusEffect } from '@react-navigation/native'
import * as Yup from 'yup'

import { Form, FormField, SubmitButton } from '../../../components/forms'

import Title from '../../../components/Title'
import Screen from '../../../components/Screen'
import useAuth from '../../../auth/useAuth'
import { defaultStyles } from '../../../config/styles'
import userQuery from '../../../api/query/user'
import { useMutation, useQueryClient } from 'react-query'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { AppStackParamList } from '../../../navigation/AppNavigator'
import { useNavigation } from '@react-navigation/native'

const validationSchema = Yup.object().shape({
  name: Yup.string().required().min(1).max(180).label('Name'),
  jobTitle: Yup.string().max(180).label('Job Title'),
})

function FormScreen({ route }) {
  const navigation = useNavigation<DrawerNavigationProp<AppStackParamList>>()

  const formikRef = React.createRef()
  const { user, renew } = useAuth()
  const queryClient = useQueryClient()

  useFocusEffect(
    React.useCallback(() => {
      if (formikRef.current) {
        console.log('form reset')
        formikRef.current?.resetForm()
        formikRef.current?.setValues({
          name: user.name,
          jobTitle: user.job_title,
        })
      }
    }, [route.params])
  )

  const userMutation = useMutation(userQuery.updateProfile, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('users')
      renew()
      navigation.navigate('PROFILE', {
        screen: 'Profile',
      })
    },
    onError: (error) => {
      return alert('Could not save your profile details')
    },
  })

  const handleSubmit = async (profile) => {
    userMutation.mutate({ userId: user.id, profileDetails: profile })
  }

  return (
    <Screen style={defaultStyles.screen}>
      <View style={defaultStyles.mainContainer}>
        <Title style={{ fontSize: 25, color: 'black' }}>Edit Profile</Title>
        <Form
          innerRef={formikRef}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          initialValues={{
            name: user.name,
            jobTitle: user.job_title,
          }}
        >
          <FormField maxLength={180} name="name" placeholder="Name" />
          <FormField maxLength={180} name="jobTitle" placeholder="Job Title" />

          <SubmitButton title="Save" />
        </Form>
      </View>
    </Screen>
  )
}

export default FormScreen
