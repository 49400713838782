import React, { useState } from 'react'
import { StyleSheet, Image } from 'react-native'
import * as Yup from 'yup'
import moment from 'moment'

import Screen from '../components/Screen'
import {
  ErrorMessage,
  Form,
  FormField,
  SubmitButton,
} from '../components/forms'
import authApi from '../api/auth'
import useAuth from '../auth/useAuth'
import Anchor from '../components/Anchor'
import AppText from '../components/Text'
import IntervalRedraw from '../components/IntervalRedraw'
import Title from '../components/Title'
import colors from '../config/colors'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { useNavigation } from '@react-navigation/native'
import { AppStackParamList } from '../navigation/AppNavigator'

const validationRegisterSchema = Yup.object().shape({
  email: Yup.string().required().email().label('Email'),
})

const validationLoginSchema = Yup.object().shape({
  email: Yup.string().required().email().label('Email'),
  password: Yup.string().required().min(4).label('Password'),
})

function LoginScreen() {
  const navigation = useNavigation<DrawerNavigationProp<AppStackParamList>>()
  const auth = useAuth()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [timeoutHandle, setTimeoutHandle] = useState<NodeJS.Timeout>()
  const [registerValidUntil, setRegisterValidUntil] = useState(moment())
  const [registerTried, setRegisterTried] = useState<boolean>(false)
  const [registerFailed, setRegisterFailed] = useState<boolean>(false)
  const [isTokenStillValid, setIsTokenStillValid] = useState<boolean>(false)
  const [registerFailedMessage, setRegisterFailedMessage] =
    useState<string>('Invalid email')
  const [loginFailed, setLoginFailed] = useState(false)
  let userInput: HTMLInputElement | null = null

  React.useEffect(() => {
    userInput?.focus()
  }, [])

  React.useEffect(() => {
    userInput?.focus()
    return navigation.addListener('beforeRemove', (timeoutHandle) => {
      if (timeoutHandle) {
        clearTimeout(timeoutHandle)
      }
    })
  }, [navigation])

  const handleSubmit = async ({ email, password }) => {
    setIsLoading(true)

    if (!registerTried || registerFailed) {
      const result = await authApi.register(email)

      if (!result.ok) {
        setRegisterFailedMessage(result.data.detail)
        setRegisterFailed(true)
      } else {
        const validDate = moment(result.data.validUntil)
        setRegisterValidUntil(validDate)
        setIsTokenStillValid(true)
        setRegisterFailed(false)

        // set timer to turn off.
        const duration = moment.duration(validDate.diff(moment()))

        console.log('fire in ' + duration.as('seconds'))
        const handle = setTimeout(() => {
          console.log('cancelled token login')
          setIsTokenStillValid(false)
          setRegisterTried(false)
        }, duration.as('seconds') * 1000 + 250)

        setTimeoutHandle(handle)
      }

      setRegisterTried(true)
      setIsLoading(false)

      return
    }

    const result = await authApi.login(email, password)
    setIsLoading(false)

    if (!result.ok) {
      return setLoginFailed(true)
    }

    setLoginFailed(false)

    if (timeoutHandle) {
      clearTimeout(timeoutHandle)
    }

    auth.logIn(result.data)
  }

  return (
    <Screen style={styles.container}>
      <Image style={styles.logo} source={require('../assets/logo-icon.png')} />
      <Title style={{ fontSize: 25, color: 'black' }}>
        Enter your email address below to get started
      </Title>

      <Form
        initialValues={{ email: '', password: '' }}
        onSubmit={handleSubmit}
        validationSchema={
          !registerTried || registerFailed
            ? validationRegisterSchema
            : validationLoginSchema
        }
      >
        <ErrorMessage
          error={registerFailedMessage}
          visible={registerFailed && registerTried}
        />
        <FormField
          autoCapitalize="none"
          autoCorrect={false}
          icon="email"
          ignoreBlur={true}
          keyboardType="email-address"
          name="email"
          placeholder="Email"
          textContentType="emailAddress"
          innerRef={function (elm) {
            userInput = elm

            return elm
          }}
          submitOnEnter={true}
        />
        {!registerFailed && registerTried && isTokenStillValid && (
          <>
            <ErrorMessage
              error="Invalid email and/or password."
              visible={loginFailed}
            />
            <AppText style={styles.info}>
              A token has been emailed to you.
              <br />
              If you haven’t received your token, please check your spam/junk
              folder.
            </AppText>
            <FormField
              autoCapitalize="none"
              autoCorrect={false}
              icon="lock"
              keyboardType="numeric"
              name="password"
              placeholder="Token"
              textContentType="none"
              submitOnEnter={true}
            />
            <AppText style={styles.info}>
              <IntervalRedraw
                delay={1000}
                enabled={isTokenStillValid}
                action={() => {
                  const duration = moment.duration(
                    registerValidUntil.diff(moment())
                  )

                  const minutes = duration.get('minutes')
                  const seconds = duration.get('seconds')

                  let message = 'The token will expire in '

                  if (minutes > 0 && seconds > 0) {
                    message += minutes + ' minutes and ' + seconds + ' seconds'
                  } else if (minutes > 0) {
                    message += minutes + ' minutes'
                  } else if (seconds > 0) {
                    message += seconds + ' seconds'
                  } else {
                    setIsLoading(false)
                    return 'The token has expired.'
                  }

                  return message + '.'
                }}
              />
            </AppText>
          </>
        )}
        <SubmitButton
          title="Sign in"
          loading={isLoading}
          disabled={isLoading}
        />

        <AppText style={styles.footerText}>{"Don't have an account?"}</AppText>
        <AppText style={styles.footerText}>
          If your email address is not working, please contact{' '}
          <Anchor href="mailto:hst@coachingimpact.co.uk" style={styles.email}>
            hst@coachingimpact.co.uk
          </Anchor>
        </AppText>
      </Form>
    </Screen>
  )
}

const styles = StyleSheet.create({
  container: {
    padding: 10,
    maxWidth: 640,
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  logo: {
    width: 80,
    height: 80,
    alignSelf: 'center',
    marginTop: 50,
    marginBottom: 20,
  },
  info: {
    textAlign: 'center',
    marginBottom: 20,
    color: colors.secondary,
  },
  footerText: {
    textAlign: 'center',
    marginTop: 10,
    color: colors.secondary,
  },
  email: {
    color: colors.primary,
  },
})

export default LoginScreen
