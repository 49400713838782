import { useEffect } from 'react'
import { View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import TitleGraphic from '../../../../../components/TileGraphic'
import AppText from '../../../../../components/Text'
import InfoButton from '../../../../../components/InfoButton'
import ButtonIcon from '../../../../../components/ButtonIcon'
import colors from '../../../../../config/colors'
import { dashboardStyles } from '../../../../../config/styles'
import useAuth from '../../../../../auth/useAuth'
import { IWidget } from './IWidget'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { AppStackParamList } from '../../../../../navigation/AppNavigator'

export const RapidReviewWidgetName = 'rapidReview'

export const RapidReviewWidget: React.FC<IWidget> = ({ editing, onLoaded }) => {
  const navigation = useNavigation<DrawerNavigationProp<AppStackParamList>>()

  useEffect(() => {
    onLoaded(RapidReviewWidgetName)
  }, [onLoaded])

  const { user } = useAuth()
  const showRapidReview = user.features.includes('FEATURE_RAPID_REVIEW')

  if (!showRapidReview) {
    return null
  }

  return (
    <div style={dashboardStyles.block}>
      <TitleGraphic
        title="Rapid Reviews"
        graphic={require('/assets/lead-rapid-review.jpg')}
        offset={false}
      />
      <View style={dashboardStyles.blockBody}>
        <AppText style={dashboardStyles.blockText}>
          The Practice of reviewing is key to learning faster and improvement.
        </AppText>

        <InfoButton
          text="Learn more about Rapid Reviews"
          onPress={() => {
            if (editing) {
              return
            }

            navigation.navigate('RESOURCE', {
              screen: 'Resource_View',
              params: {
                slug: 'rapid-review',
              },
            })
          }}
        />

        <View style={{ flexDirection: 'row' }}>
          <View style={{ flex: 1 }}>
            <ButtonIcon
              text="New Rapid Review"
              icon="text-box-plus-outline"
              color={colors.white}
              backgroundColor={colors.primary}
              onPress={() => {
                if (editing) {
                  return
                }

                navigation.navigate('RAPID_REVIEW', {
                  screen: 'RapidReview_Form',
                  params: {},
                })
              }}
            />
          </View>
        </View>

        <View style={{ flexDirection: 'row' }}>
          <View style={{ flex: 1 }}>
            <ButtonIcon
              text="Your Reviews"
              icon="text-box-search-outline"
              color={colors.primary}
              backgroundColor={colors.white}
              borderColor={colors.primary}
              onPress={() => {
                if (editing) {
                  return
                }

                navigation.navigate('RAPID_REVIEW', {
                  screen: 'RapidReview_List',
                })
              }}
            />
          </View>
        </View>

        <View style={{ flexDirection: 'row' }}>
          <View style={{ flex: 1 }}>
            <ButtonIcon
              text="Join Team Rapid Review"
              icon="account-multiple"
              color={colors.primary}
              backgroundColor={colors.white}
              borderColor={colors.primary}
              onPress={() => {
                if (editing) {
                  return
                }

                navigation.navigate('RAPID_REVIEW', {
                  screen: 'Meeting_Join',
                  params: {
                    rapidReview: null,
                  },
                })
              }}
            />
          </View>
        </View>

        <View style={{ flexDirection: 'row' }}>
          <View style={{ flex: 1 }}>
            <ButtonIcon
              text="Generate Rapid Review Code"
              icon="code-tags"
              color={colors.primary}
              backgroundColor={colors.white}
              borderColor={colors.primary}
              onPress={() => {
                if (editing) {
                  return
                }

                navigation.navigate('RAPID_REVIEW', {
                  screen: 'Meeting_Form',
                  params: {
                    meeting: null,
                  },
                })
              }}
            />
          </View>
        </View>
      </View>
    </div>
  )
}
