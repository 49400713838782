import React from 'react'
import { FlatList, StyleSheet, View } from 'react-native'

import AppText from '../../../../components/Text'
import colors from '../../../../config/colors'
import { defaultStyles } from '../../../../config/styles'
import Screen from '../../../../components/Screen'
import Button from '../../../../components/Button'
import { format, parseISO } from 'date-fns'
import TitleGraphic from '../../../../components/TitleGraphic'

import meetingQuery from '../../../../api/query/meeting'
import ActivityIndicator from '../../../../components/ActivityIndicator'
import RapidReviewButton from '../../../../components/screen-button/RapidReviewButton'
import useAuth from '../../../../auth/useAuth'
import { toUUID } from '../../../../utility/resource'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { AppStackParamList } from '../../../../navigation/AppNavigator'
import { useNavigation } from '@react-navigation/native'

function PastMeetingScreen({ route }) {
  const navigation = useNavigation<DrawerNavigationProp<AppStackParamList>>()
  const { user } = useAuth()
  const meetingId = route.params.meetingId

  const meetingReviewsApi = meetingQuery.getMeetingReviews(meetingId)
  const meetingApi = meetingQuery.getPastMeeting(meetingId)
  const meetingOwner = user.id === toUUID(meetingApi.data?.organiser)

  return (
    <Screen style={defaultStyles.screen}>
      <ActivityIndicator visible={meetingApi.isLoading} />
      <TitleGraphic
        section="Meetings"
        title="Rapid Reviews"
        graphic={require('../../../../assets/lead-rapid-review.jpg')}
      />
      <View style={defaultStyles.mainContainer}>
        {meetingApi.isSuccess && (
          <>
            <View style={styles.table}>
              <View style={[styles.row]}>
                <AppText style={styles.heading}>Meeting: </AppText>
                <AppText>{meetingApi.data.name}</AppText>
              </View>
              <View style={[styles.row]}>
                <AppText style={styles.heading}>Date: </AppText>
                <AppText>
                  {format(parseISO(meetingApi.data.date), 'do MMMM yyyy')}
                </AppText>
              </View>
              <View style={[styles.row]}>
                <AppText style={styles.heading}>
                  Average Meeting Intent Rating:{' '}
                </AppText>
                {meetingApi.data.averageRating !== null && (
                  <AppText>{meetingApi.data.averageRating} out of 10</AppText>
                )}
                {meetingApi.data.averageRating === null && (
                  <AppText>No rapid reviews</AppText>
                )}
              </View>
              <View style={[styles.row]}>
                <AppText style={styles.heading}>Attendees: </AppText>
                <FlatList
                  data={meetingApi.data.attendees}
                  keyExtractor={(attendee) => {
                    return attendee.id.toString()
                  }}
                  renderItem={({ item }) => <AppText>{item.name}</AppText>}
                  ListEmptyComponent={
                    <View style={styles.card}>
                      <AppText>No rapid reviews</AppText>
                    </View>
                  }
                />
              </View>

              {meetingOwner && (
                <>
                  <View style={[styles.row, { flexDirection: 'column' }]}>
                    <AppText style={[styles.heading]}>Rapid reviews: </AppText>
                    <ActivityIndicator visible={meetingReviewsApi.isLoading} />
                    {meetingReviewsApi.isError && (
                      <>
                        <AppText>Meetings</AppText>
                        <AppText>Couldn't retrieve the meetings.</AppText>
                        <Button
                          title="Retry"
                          onPress={() => meetingReviewsApi.refetch()}
                        />
                      </>
                    )}
                    {meetingReviewsApi.isSuccess && (
                      <>
                        <FlatList
                          data={meetingReviewsApi.data}
                          keyExtractor={(meeting) => {
                            return meeting.id.toString()
                          }}
                          renderItem={({ item }) => (
                            <RapidReviewButton
                              date={format(parseISO(item.date), 'do MMMM yyyy')}
                              meetingName={item.meetingName}
                              id={item.id}
                              onPress={() => {
                                navigation.navigate('RAPID_REVIEW', {
                                  screen: 'Meeting_RapidReview_View',
                                  params: {
                                    rapidReview: item,
                                  },
                                })
                              }}
                            />
                          )}
                          ListEmptyComponent={
                            <View style={styles.card}>
                              <AppText>No rapid reviews</AppText>
                            </View>
                          }
                        />
                      </>
                    )}
                  </View>
                </>
              )}
            </View>
            {meetingOwner && (
              <>
                <AppText style={styles.heading}>
                  Suggestion to take forward
                </AppText>

                <View style={styles.card}>
                  <AppText>{meetingApi.data.suggestion}</AppText>
                  <AppText>
                    Taken forward by{' '}
                    <AppText style={{ fontWeight: 'bold' }}>
                      {meetingApi.data.suggestionOwner
                        ? meetingApi.data.suggestionOwner.name
                        : 'Unknown'}
                    </AppText>
                  </AppText>
                </View>
              </>
            )}
          </>
        )}
        <Button
          title="Back to meetings"
          onPress={() => {
            navigation.navigate('RAPID_REVIEW', {
              screen: 'Meeting_List',
            })
          }}
          style={{ marginLeft: 0 }}
        />
      </View>
    </Screen>
  )
}

const styles = StyleSheet.create({
  meetingCode: {
    justifyContent: 'flex-start',
    marginVertical: 100,
    width: '100%',
  },
  code: {
    fontSize: 24,
    fontWeight: 'bold',
    letterSpacing: 10,
  },
  card: {
    borderRadius: 10,
    backgroundColor: colors.light,
    marginBottom: 20,
    overflow: 'hidden',
    padding: 20,
  },
  panel: {
    backgroundColor: colors.light,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  text: {
    fontSize: 25,
    fontWeight: '600',
    marginVertical: 20,
  },
  heading: {
    fontWeight: '700',
    minWidth: 150,
  },
  table: {},
  row: {
    flexDirection: 'row',
    paddingVertical: 20,
  },
})

export default PastMeetingScreen
