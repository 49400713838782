import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { IWidget } from '../../screens/app/Dashboard/components/Widgets/IWidget'

export interface IWidgetItem {
  i: string
  x: number
  y: number
  w: number
  h: number
}

export interface IWidgetLayout {
  lg: IWidgetItem[]
  md: IWidgetItem[]
  sm: IWidgetItem[]
  xs: IWidgetItem[]
  xxs: IWidgetItem[]
}

export interface DashboardState {
  widgets: IWidgetItem[]
  isFetched: boolean
}

const INITIAL_STATE: DashboardState = {
  widgets: [],
  isFetched: false,
}

const dashboard = createSlice({
  name: 'dashboard',
  initialState: INITIAL_STATE,
  reducers: {
    setWidgets: (state, action: PayloadAction<IWidgetItem[]>) => {
      state.widgets = action.payload
      state.isFetched = true
    },
    addWidget: (state, action: PayloadAction<IWidgetItem>) => {
      state.widgets = [...state.widgets, action.payload]
    },
    removeWidget: (state, action: PayloadAction<string>) => {
      state.widgets = state.widgets.filter((item) => item.i !== action.payload)
    },
  },
})

export const selectDashboardSlice = (state: RootState) => state.dashboard

export const selectWidgets = createSelector(
  selectDashboardSlice,
  (slice): IWidgetItem[] => slice.widgets
)

export const selectWidgetLayout = createSelector(
  selectDashboardSlice,
  (slice): IWidgetLayout => {
    return {
      lg: slice.widgets,
      md: slice.widgets,
      sm: slice.widgets,
      xs: slice.widgets,
      xxs: slice.widgets,
    }
  }
)

export const dashboardReducer = dashboard.reducer

export const { setWidgets, addWidget, removeWidget } = dashboard.actions
