import React from 'react'
import { StyleSheet, View } from 'react-native'
import { useFocusEffect } from '@react-navigation/native'

import ActivityIndicator from '../../../components/ActivityIndicator'
import meetingApi from '../../../api/meeting'
import colors from '../../../config/colors'
import { defaultStyles } from '../../../config/styles'
import Screen from '../../../components/Screen'
import Button from '../../../components/Button'
import AppText from '../../../components/Text'
import useApi from '../../../hooks/useApi'
import useAuth from '../../../auth/useAuth'
import ButtonIcon from '../../../components/ButtonIcon'
import {
  IN_PROGRESS,
  MeetingList,
  UPCOMING,
} from '../../../components/rapid-review/MeetingList'
import TitleGraphic from '../../../components/TitleGraphic'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { AppStackParamList } from '../../../navigation/AppNavigator'
import { useNavigation } from '@react-navigation/native'

function HubScreen() {
  const navigation = useNavigation<DrawerNavigationProp<AppStackParamList>>()
  const { user } = useAuth()
  const getMeetingApi = useApi(meetingApi.getMeetings)

  useFocusEffect(
    React.useCallback(() => {
      if (getMeetingApi) {
        getMeetingApi
          .request(user.id)
          .then((response) => {
            // console.log("data", response.data);
          })
          .catch((error) => {
            console.log(error)
          })
      }
    }, [])
  )

  return (
    <Screen style={defaultStyles.screen}>
      <TitleGraphic
        title="Rapid Reviews"
        graphic={require('../../../assets/lead-rapid-review.jpg')}
      />
      <View style={defaultStyles.mainContainer}>
        <View style={styles.block}>
          <AppText style={styles.blockTitle}>Personal Rapid Reviews</AppText>
          <AppText style={styles.blockText}>
            The Practice of reviewing is key to learning faster and improvement.
            Whether it is the review of a meeting, a presentation, a 1-to-1 or
            even of your own performance, the practice of pausing to reflect on
            what was useful and what would have made the interaction more
            useful, enables you to learn faster and incorporate improvements as
            you go forward.
          </AppText>

          <View style={{ flexDirection: 'row' }}>
            <View style={{ flex: 1 }}>
              <ButtonIcon
                text="Create a Rapid Review"
                icon="text-box-plus-outline"
                color={colors.white}
                backgroundColor={colors.primary}
                onPress={() => {
                  navigation.navigate('RAPID_REVIEW', {
                    screen: 'RapidReview_Form',
                    params: {},
                  })
                }}
              />
            </View>
          </View>

          <View style={{ flexDirection: 'row' }}>
            <View style={{ flex: 1 }}>
              <ButtonIcon
                text="Your Rapid Reviews"
                icon="text-box-search-outline"
                color={colors.primary}
                backgroundColor={colors.white}
                borderColor={colors.primary}
                onPress={() => {
                  navigation.navigate('RAPID_REVIEW', {
                    screen: 'RapidReview_List',
                  })
                }}
              />
            </View>
          </View>
        </View>

        <View style={styles.block}>
          <AppText style={styles.blockTitle}>Team Rapid Reviews</AppText>
          <AppText style={styles.blockText}>
            Creating your team rapid review here will allow all the people you
            invite to take part easily in the Rapid Review. <br />
            That, in turn, will create mutual accountability as you progress,
            improve future meetings and learn faster.
          </AppText>

          <View style={{ flexDirection: 'row' }}>
            <View style={{ flex: 1 }}>
              <ButtonIcon
                text="Join Team Rapid Review"
                icon="account-multiple"
                color={colors.white}
                backgroundColor={colors.primary}
                onPress={() => {
                  navigation.navigate('RAPID_REVIEW', {
                    screen: 'Meeting_Join',
                    params: {},
                  })
                }}
              />
            </View>
          </View>

          <View style={{ flexDirection: 'row' }}>
            <View style={{ flex: 1 }}>
              <ButtonIcon
                text="Generate Rapid Review Code"
                icon="code-tags"
                color={colors.primary}
                backgroundColor={colors.white}
                borderColor={colors.primary}
                onPress={() => {
                  navigation.navigate('RAPID_REVIEW', {
                    screen: 'Meeting_Form',
                    params: {},
                  })
                }}
              />
            </View>
          </View>

          <View style={{ flexDirection: 'row' }}>
            <View style={{ flex: 1 }}>
              <ButtonIcon
                text="Past Team Rapid Reviews"
                icon="text-box-search-outline"
                color={colors.primary}
                backgroundColor={colors.white}
                borderColor={colors.primary}
                onPress={() => {
                  navigation.navigate('RAPID_REVIEW', {
                    screen: 'Meeting_List',
                  })
                }}
              />
            </View>
          </View>
        </View>

        <ActivityIndicator visible={getMeetingApi.loading} />
        <View style={{ marginTop: '50px' }}>
          {getMeetingApi.error && (
            <>
              <AppText style={styles.title}>Review Sessions</AppText>
              <AppText>Couldn't retrieve the review sessions.</AppText>
              <Button
                title="Retry"
                onPress={() => getMeetingApi.request(user.id)}
              />
            </>
          )}
          {!getMeetingApi.error && (
            <>
              <MeetingList
                data={getMeetingApi.data}
                display={UPCOMING}
                title="Upcoming sessions"
              />
              <MeetingList
                data={getMeetingApi.data}
                display={IN_PROGRESS}
                title="Active review sessions"
                noDataMessage="There are currently no active review sessions"
              />
            </>
          )}
        </View>
      </View>
    </Screen>
  )
}

const styles = StyleSheet.create({
  block: {
    backgroundColor: colors.light,
    width: '100%',
    paddingVertical: 20,
    paddingHorizontal: 20,
    borderRadius: 10,
    marginTop: 20,
  },
  blockTitle: {
    fontSize: 20,
    fontWeight: '600',
    marginBottom: 12,
  },
  blockText: {
    fontSize: 16,
    fontWeight: '400',
    lineHeight: '1.5',
    marginBottom: 12,
  },
  text: {
    fontSize: 25,
    fontWeight: '600',
    marginVertical: 20,
  },
  title: {
    fontWeight: '600',
  },
})

export default HubScreen
