import { useContext } from 'react'

import { AuthContext, AuthContextType } from './AuthContext'
import authStorage from './storage'

const useAuth = () => {
  const context = useContext<AuthContextType | null>(AuthContext)

  if (!context) {
    throw new Error('AuthContext must be placed within AuthProvider')
  }

  const { user, setUser } = context
  const logIn = async (authToken) => {
    authStorage.storeToken(authToken)

    const userPayload = await authStorage.getUser()
    setUser(userPayload)
  }

  const logOut = () => {
    setUser(null)
    authStorage.removeToken()
  }

  const renew = async () => {
    const token = await authStorage.renewToken()
    logIn(token)
  }

  return { user, logIn, logOut, renew }
}

export default useAuth
