import React, { useState } from 'react'
import { NavigationContainer } from '@react-navigation/native'
import AppLoading from './src/components/startup/AppLoading'
import ErrorBoundary from 'react-native-error-boundary'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Provider } from 'react-redux'

import navigationTheme from './src/navigation/navigationTheme'
import AppNavigator from './src/navigation/AppNavigator'
import OfflineNotice from './src/components/OfflineNotice'
import AuthNavigator from './src/navigation/AuthNavigator'
import { AuthContext, AuthContextType } from './src/auth/AuthContext'
import authStorage, { IUser } from './src/auth/storage'
import * as rootNavigation from './src/navigation/rootNavigation'
import logger from './src/utility/logger'
import ErrorScreen from './src/screens/ErrorScreen'
import { post } from './src/utility/api'
import { linking } from './src/config/linking'
import StartUpScreen from './src/components/startup/StartUpScreen'
import { GestureHandlerRootView } from 'react-native-gesture-handler'
import { store } from './src/redux/store'

logger.start()

export default function App() {
  const [user, setUser] = useState<IUser>({
    company: '',
    company_id: '',
    exp: 0,
    features: [],
    group: '',
    group_id: '',
    iat: 0,
    id: '',
    ip: '',
    job_title: '',
    name: '',
    rituals: [],
    roles: [],
    username: '',
  } as IUser)
  const [isReady, setIsReady] = useState(false)

  authStorage.watchToken(async () => {
    const user = await authStorage.getUser()

    if (user) {
      setUser(user)
    }
  })

  const restoreUser = async () => {
    const user = await authStorage.getUser()

    if (user) {
      setUser(user)
    }
  }

  const handleError = (error) => {
    console.warn(error)
  }

  if (!isReady) {
    return (
      <AppLoading
        startAsync={restoreUser}
        onFinish={() => setIsReady(true)}
        onError={handleError}
      />
    )
  }

  console.log('user', user)
  // https://reactnavigation.org/docs/navigation-container/#documenttitleenabled

  const errorHandler = (error: Error, stackTrace: string) => {
    /* Log the error to an error reporting service */
    console.log('Error Occurred', error, stackTrace)

    post('/error_reports', {
      errorMessage: JSON.stringify({
        name: error.name,
        message: error.message,
      }),
      stackTrace: JSON.stringify(stackTrace),
    })
  }

  const queryClient = new QueryClient()

  return (
    <GestureHandlerRootView style={{ flex: 1 }}>
      <AuthContext.Provider value={{ user, setUser } as AuthContextType}>
        <OfflineNotice />
        <ErrorBoundary
          FallbackComponent={ErrorScreen}
          onError={errorHandler}
          key={location.pathname}
        >
          <NavigationContainer
            theme={navigationTheme}
            linking={linking}
            ref={rootNavigation.navigationRef}
            documentTitle={{
              formatter: (options, route) => `Coaching Impact`,
            }}
            // onReady={() => {
            //   rootNavigation.isReadyRef.current = true;
            // }}
          >
            {user !== null && typeof user === 'object' && user.ip ? (
              <Provider store={store}>
                <QueryClientProvider client={queryClient}>
                  <AppNavigator />
                  <StartUpScreen />
                </QueryClientProvider>
              </Provider>
            ) : (
              <AuthNavigator />
            )}
          </NavigationContainer>
        </ErrorBoundary>
      </AuthContext.Provider>
    </GestureHandlerRootView>
  )
}
