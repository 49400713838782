import Constants from 'expo-constants'

const settings = {
  dev: {
    apiUrl: 'http://api.comparisontechnology.com:8079',
    imageUrl: 'http://app.comparisontechnology.com:8079',
  },
  staging: {
    apiUrl: 'http://api.comparisontechnology.com',
    imageUrl: 'http://app.comparisontechnology.com',
  },
  prod: {
    apiUrl: 'https://api.coachingimpact.com',
    imageUrl: 'https://app.coachingimpact.com',
  },
}

const getCurrentSettings = () => {
  if (__DEV__) return settings.dev
  //   if (Constants.manifest.releaseChannel === "staging") return settings.staging;
  return settings.prod
}

export default getCurrentSettings()
