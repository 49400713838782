import { Component, ReactNode } from 'react'

interface IState {
  delay: number
  renderAction?: () => ReactNode
  enabled: boolean
}

export default class IntervalRedraw extends Component {
  interval: ReturnType<typeof setTimeout> | null = null
  state: IState

  constructor(props) {
    super(props)
    const { action, enabled, delay = 1 } = props
    this.state = {
      delay: delay * 1000,
      renderAction: action,
      enabled: enabled,
    }
  }

  componentDidMount() {
    this.interval = setInterval(() => this.redraw(), 1000)
  }

  componentWillUnmount() {
    if (null === this.interval) {
      return
    }
    clearInterval(this.interval)
  }

  redraw() {
    if (this.state.enabled && this.forceUpdate) {
      this.forceUpdate()
    }
  }

  render() {
    return this.state.renderAction && this.state.enabled
      ? this.state.renderAction()
      : null
  }
}
