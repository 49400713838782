import React from 'react'
import { View } from 'react-native'

import Screen from '../../../components/Screen'
import colors from '../../../config/colors'
import { defaultStyles } from '../../../config/styles'
import ButtonIcon from '../../../components/ButtonIcon'
import TitleGraphic from '../../../components/TitleGraphic'
import { useNavigation } from '@react-navigation/native'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { AppStackParamList } from '../../../navigation/AppNavigator'

function IntroScreen() {
  const navigation = useNavigation<DrawerNavigationProp<AppStackParamList>>()

  return (
    <Screen style={defaultStyles.screen}>
      <TitleGraphic
        section="Best Out Of Me"
        title="My B.O.O.M"
        graphic={require('../../../assets/lead-boom.jpg')}
      />
      <View style={defaultStyles.mainContainer}>
        <View style={{ flexDirection: 'row' }}>
          <View style={{ flex: 1 }}>
            <ButtonIcon
              text="My BOSS Practices"
              icon="playlist-check"
              color={colors.white}
              backgroundColor={colors.primary}
              onPress={() => {
                navigation.navigate('BOSS', {
                  screen: 'Boss_List',
                })
              }}
            />
          </View>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <View style={{ flex: 1 }}>
            <ButtonIcon
              text="My Trophy Cabinet"
              icon="trophy-variant"
              color={colors.white}
              backgroundColor={colors.primary}
              onPress={() => {
                navigation.navigate('TROPHY_CABINET', {
                  screen: 'Trophy_List',
                })
              }}
            />
          </View>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <View style={{ flex: 1 }}>
            <ButtonIcon
              text="Boom"
              icon="account-filter"
              color={colors.white}
              backgroundColor={colors.primary}
              onPress={() => {
                navigation.navigate('BOOM', {
                  screen: 'Boom',
                })
              }}
            />
          </View>
        </View>
      </View>
    </Screen>
  )
}

export default IntroScreen
