import React from 'react'
import { FlatList, View } from 'react-native'

import ActivityIndicator from '../../../components/ActivityIndicator'
import teamQuery from '../../../api/query/team'
import Screen from '../../../components/Screen'
import Button from '../../../components/Button'
import AppText from '../../../components/Text'
import colors from '../../../config/colors'
import { defaultStyles } from '../../../config/styles'
import ButtonIcon from '../../../components/ButtonIcon'
import useAuth from '../../../auth/useAuth'
import TeamButton from '../../../components/screen-button/TeamButton'
import TitleGraphic from '../../../components/TitleGraphic'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { AppStackParamList } from '../../../navigation/AppNavigator'
import { useNavigation } from '@react-navigation/native'

function ListScreen() {
  const navigation = useNavigation<DrawerNavigationProp<AppStackParamList>>()
  const { user } = useAuth()
  const teamApi = teamQuery.getTeams(user.id)

  return (
    <Screen style={defaultStyles.screen}>
      <TitleGraphic
        section="Team"
        title="My Teams"
        graphic={require('../../../assets/lead-rapid-review.jpg')}
      />
      <View style={defaultStyles.mainContainer}>
        <ButtonIcon
          text="Create a Team"
          icon="text-box-plus-outline"
          color={colors.white}
          backgroundColor={colors.primary}
          onPress={() => {
            navigation.navigate('TEAM', {
              screen: 'Team_Form',
              params: {},
            })
          }}
        />
        <ActivityIndicator visible={teamApi.isLoading} />
        {teamApi.isError && (
          <>
            <AppText>Couldn't retrieve your teams.</AppText>
            <Button title="Retry" onPress={() => teamApi.refetch()} />
          </>
        )}
        {teamApi.isSuccess && (
          <FlatList
            style={{ marginTop: 15 }}
            data={teamApi.data}
            keyExtractor={(team) => {
              return team.id.toString()
            }}
            renderItem={({ item }) => (
              <TeamButton
                teamName={item.name}
                onPress={() => {
                  navigation.navigate('TEAM', {
                    screen: 'Team_View',
                    params: {
                      teamId: item.id,
                    },
                  })
                }}
              />
            )}
            ListEmptyComponent={
              <AppText>There are currently no Teams defined</AppText>
            }
          />
        )}
      </View>
    </Screen>
  )
}

export default ListScreen
