import React from 'react'
import {
  ImageBackground,
  ScrollView,
  StyleSheet,
  View,
  Image,
} from 'react-native'

import Button from '../components/Button'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { AppStackParamList } from '../navigation/AppNavigator'
import { useNavigation } from '@react-navigation/native'

function WelcomeScreen() {
  const navigation = useNavigation<DrawerNavigationProp<AppStackParamList>>()

  return (
    <ScrollView style={styles.scrollView}>
      <ImageBackground style={styles.background}>
        <View style={styles.logoContainer}>
          <Image style={styles.logo} source={require('../assets/logo.png')} />
        </View>
        <View style={styles.splashContainer}>
          <Image
            style={styles.splash}
            source={require('../assets/splash-animation.gif')}
          />
        </View>
        <View style={styles.habitContainer}>
          <Image
            style={styles.habit}
            source={require('../assets/habit-builder.png')}
          />
        </View>
        <View style={styles.buttonsContainer}>
          <Button
            title="Get started"
            onPress={() => navigation.navigate('Login')}
          />
        </View>
      </ImageBackground>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  background: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    minHeight: 640,
  },
  buttonsContainer: {
    padding: 20,
    width: '100%',
    maxWidth: 360,
  },
  logo: {
    width: 300,
    height: 100,
  },
  scrollView: {
    flex: 1,
  },
  splash: {
    width: 300,
    height: 426,
  },
  habit: {
    width: 300,
    height: 50,
  },
  logoContainer: {
    position: 'absolute',
    top: 40,
    alignItems: 'center',
  },
  splashContainer: {
    position: 'absolute',
    top: '50%',
    marginTop: -223,
    alignItems: 'center',
  },
  habitContainer: {
    position: 'absolute',
    bottom: 120,
    alignItems: 'center',
  },
  tagline: {
    fontSize: 25,
    fontWeight: '600',
    paddingVertical: 20,
  },
})

export default WelcomeScreen
