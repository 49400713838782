import React, { useEffect, useState } from 'react'
import { Alert, Platform, StyleSheet, View } from 'react-native'
import { useMutation, useQueryClient } from 'react-query'
import * as Yup from 'yup'
import { FieldArray, FormikContext } from 'formik'

import Screen from '../../../components/Screen'
import useAuth from '../../../auth/useAuth'
import criticalImpactOpportunityQuery from '../../../api/query/criticalImpactOpportunity'
import bossQuery from '../../../api/query/boss'

import { defaultStyles, defaultConfig } from '../../../config/styles'
import colors from '../../../config/colors'
import TitleGraphic from '../../../components/TitleGraphic'
import { ICio } from 'types/cio'
import AppText from '../../../components/Text'
import ActivityIndicator from '../../../components/ActivityIndicator'
import AppButton from '../../../components/Button'
import { FormikStep } from '../../../components/formik/FormikStep'
import CioTitle from '../../../components/cio/CioTitle'
import { FormDatePicker, FormField } from '../../../components/forms'
import {
  FormNudge,
  NudgeValidationSchema,
} from '../../../components/formik/FormNudge'
import { FormikStepper } from '../../../components/formik/FormikStepper'
import BossLabel from '../../../components/boss/label/BossLabel'
import FormBossChoice from '../../../components/forms/FormBossChoice'
import Contributors from '../../../components/cio/Contributors'
import { Sections } from '../../../utility/cioSection'
import IsNotificationDisabled from '../../../components/IsNotificationDisabled'
import { DisplayFieldValue } from '../../../components/formik/DisplayFieldValue'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { AppStackParamList } from '../../../navigation/AppNavigator'
import { useNavigation } from '@react-navigation/native'

export default function FormPrepScreen({ route }) {
  const navigation = useNavigation<DrawerNavigationProp<AppStackParamList>>()
  const cioId: string | null = route.params?.criticalImpactOpportunityId ?? null
  const { isLoading, isSuccess, isError, data, refetch } =
    criticalImpactOpportunityQuery.getCriticalImpactOpportunity(cioId)

  const queryClient = useQueryClient()
  const [step, setStep] = useState(0)
  const { user } = useAuth()
  const bossApi = bossQuery.getBosses(user.id)

  useEffect(() => {
    return navigation.addListener('focus', () => {
      setStep(0)
    })
  }, [navigation])

  const cioUpdateMutation = useMutation(
    criticalImpactOpportunityQuery.updateCriticalImpactOpportunity,
    {
      onSuccess: (data: ICio) => {
        queryClient.invalidateQueries('cios')
        queryClient.invalidateQueries([
          'cio',
          { criticalImpactOpportunityId: data.id },
        ])

        if (Platform.OS === 'web') {
          if (confirm('Would you like to exit the CIO?')) {
            navigation.navigate('CRITICAL_IMPACT_OPPORTUNITY', {
              screen: 'CriticalImpactOpportunity_Form',
              params: {
                criticalImpactOpportunityId: data.id,
              },
            })
          }
        } else {
          Alert.alert('CIO', 'Would you like to exit the CIO?', [
            {
              text: 'Yes',
              onPress: () =>
                navigation.navigate('CRITICAL_IMPACT_OPPORTUNITY', {
                  screen: 'CriticalImpactOpportunity_Form',
                  params: {
                    criticalImpactOpportunityId: data.id,
                  },
                }),
            },
            { text: 'No' },
          ])
        }
      },
      onError: (error) => {
        alert('Failed to update the CIO')
        console.log(error)
      },
    }
  )

  return (
    <Screen style={defaultStyles.screen}>
      <TitleGraphic
        section="Critical Impact Opportunities"
        title="Create a CIO"
        graphic={require('../../../assets/lead-cio.jpg')}
      />
      <View style={defaultStyles.mainContainer}>
        {isLoading && <ActivityIndicator visible={isLoading} />}
        {isError && (
          <>
            <AppText>Couldn't retrieve the CIO.</AppText>
            <AppButton title="Retry" onPress={() => refetch()} />
          </>
        )}
        {isSuccess && (
          <FormikStepper
            initialValues={data}
            onSubmit={(newCio) => {
              newCio.section = Sections.prep
              cioUpdateMutation.mutate(newCio)
            }}
            step={step}
            setStep={setStep}
          >
            <FormikStep
              validationSchema={Yup.object({
                whoAudience: Yup.string()
                  .required()
                  .min(1)
                  .label('Who is your audience?'),
                humanConnection: Yup.string()
                  .required()
                  .min(1)
                  .label(
                    'What do you know about them to help you connect on a human level?'
                  ),
                humanIntent: Yup.string()
                  .required()
                  .min(1)
                  .label(
                    'What do you know about them to help you connect on a human level?'
                  ),
              })}
            >
              <CioTitle title="Prepare - Your Impact" />
              <View style={defaultStyles.mainContainer}>
                <AppText style={defaultStyles.questionWithPadding}>
                  Who is your audience?
                </AppText>
                <FormField
                  multiline
                  name="whoAudience"
                  numberOfLines={3}
                  placeholder="Name &amp; Job Title"
                />
                <AppText style={defaultStyles.questionWithPadding}>
                  What could be your Human Connect?
                </AppText>
                <FormField
                  multiline
                  name="humanConnection"
                  numberOfLines={3}
                  placeholder="What do you know about them to help you connect on a human level?"
                />
                <AppText style={defaultStyles.questionWithPadding}>
                  What’s the Purpose/Intent?
                </AppText>
                <FormField
                  multiline
                  name="humanIntent"
                  numberOfLines={3}
                  placeholder="The impact I intend to have is..."
                />
              </View>
            </FormikStep>
            <FormikStep
              validationSchema={Yup.object({
                audienceDo: Yup.string().required().min(1).label('Do'),
                audienceFeel: Yup.string().required().min(1).label('Feel'),
                audienceKnow: Yup.string().required().min(1).label('Know'),
              })}
            >
              <CioTitle title="Prepare - Your Impact" />
              <AppText>
                Now that you know your audience and why the CIO matters…
              </AppText>
              <View style={styles.questionContainer}>
                <AppText style={defaultStyles.questionWithPadding}>
                  What do you want them to DO?
                </AppText>
                <FormField
                  multiline
                  name="audienceDo"
                  numberOfLines={2}
                  placeholder="What action do you want your audience to take?"
                />
                <AppText style={defaultStyles.questionWithPadding}>
                  What do you want them to FEEL?
                </AppText>
                <FormField
                  multiline
                  name="audienceFeel"
                  numberOfLines={2}
                  placeholder="How do you want to move them emotionally?"
                />
                <AppText>
                  Bearing in mind your answers to the above questions…
                </AppText>
                <AppText style={defaultStyles.questionWithPadding}>
                  What do you want them to KNOW?
                </AppText>
                <FormField
                  multiline
                  name="audienceKnow"
                  numberOfLines={2}
                  placeholder="What will you tell them?"
                />
              </View>
            </FormikStep>
            <FormikStep
              validationSchema={Yup.object().shape({
                tasks: Yup.array()
                  .min(1)
                  .of(Yup.string().required().min(1).label('This'))
                  .label('This'),
              })}
            >
              <CioTitle title="Prepare - Your Impact" />
              <View style={defaultStyles.mainContainer}>
                <AppText style={defaultStyles.questionWithPadding}>
                  Knowing your audience, list what needs planning/doing to
                  create the impact you want in this CIO
                </AppText>
                <FieldArray
                  name="tasks"
                  render={(arrayHelpers) => (
                    <div>
                      {arrayHelpers.form.values.tasks.map((task, index) => {
                        return (
                          <div key={index}>
                            <button
                              type="button"
                              onClick={() => {
                                arrayHelpers.remove(index)
                              }}
                              style={{
                                borderWidth: 0,
                                fontWeight: 600,
                                position: 'absolute',
                                marginTop: 15,
                                right: 15,
                                backgroundColor: '#ddd',
                                color: '#777',
                                borderRadius: 10,
                                width: 20,
                                height: 20,
                                fontSize: 16,
                                zIndex: 10,
                              }}
                            >
                              -
                            </button>
                            <FormField
                              name={`tasks.${index}`}
                              placeholder="e.g., build presentation, future story"
                            />
                          </div>
                        )
                      })}

                      <button
                        type="button"
                        onClick={() => {
                          arrayHelpers.push('')
                        }}
                        style={{
                          backgroundColor: '#ffffff',
                          borderWidth: 0,
                          fontFamily: 'Open Sans',
                          fontSize: 16,
                          fontWeight: 600,
                          color: '#3EACBE',
                          marginTop: 20,
                          width: '100%',
                        }}
                      >
                        + Add an item
                      </button>
                    </div>
                  )}
                />
              </View>
            </FormikStep>
            <FormikStep
              validationSchema={Yup.object().shape({
                feedbackFrom: Yup.array()
                  .min(1)
                  .of(
                    Yup.object().shape({
                      type: Yup.string().required(),
                      value: Yup.string()
                        .required()
                        .when('type', {
                          is: 'EMAIL',
                          then: Yup.string().email(),
                          otherwise: Yup.string().matches(
                            /^[0-9a-f]{8}-[0-9a-f]{4}-[0-6][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i,
                            {
                              name: 'uuid',
                              excludeEmptyString: false,
                            }
                          ),
                        }),
                      label: Yup.string().required(),
                    })
                  )
                  .label('Contacts'),
              })}
            >
              <CioTitle title="Prepare - Your Content" />
              <View style={defaultStyles.mainContainer}>
                <AppText style={defaultStyles.questionWithPadding}>
                  Who is contributing to this CIO?
                </AppText>
                <Contributors />
                <AppText>
                  Contributors will see your finished CIO in “Shared With Me”
                </AppText>
              </View>
            </FormikStep>
            <FormikStep
              validationSchema={Yup.object({
                prepBefore: Yup.string().required().min(1).label('Before'),
                prepDuring: Yup.string().required().min(1).label('During'),
                prepAfter: Yup.string().required().min(1).label('After'),
              })}
            >
              <CioTitle title="Prepare - Your Impact" />
              <AppText>
                To have the best possible connection to your audience, it is
                useful to connect with them before, during and after your CIO -{' '}
                <strong>and to know in advance how you will do that.</strong>
              </AppText>
              <View>
                <AppText style={defaultStyles.questionWithPadding}>
                  Before
                </AppText>
                <FormField
                  multiline
                  name="prepBefore"
                  numberOfLines={2}
                  placeholder="e.g. email them with agenda"
                />
                <AppText style={defaultStyles.questionWithPadding}>
                  During
                </AppText>
                <FormField
                  multiline
                  name="prepDuring"
                  numberOfLines={2}
                  placeholder="e.g. Human connect / do a fast rapid review"
                />
                <AppText style={defaultStyles.questionWithPadding}>
                  After
                </AppText>
                <FormField
                  multiline
                  name="prepAfter"
                  numberOfLines={2}
                  placeholder="e.g. email with outputs / schedule follow up"
                />
              </View>
            </FormikStep>
            <FormikStep
              validationSchema={Yup.object({
                prepareTime: Yup.string().required().label('How much time?'),
              })}
            >
              <CioTitle title="Prepare - Your Impact" />
              <View style={defaultStyles.mainContainer}>
                <View style={[defaultStyles.lightBox]}>
                  <AppText
                    style={[
                      defaultStyles.questionWithPadding,
                      { marginTop: 0, marginBottom: 20, paddingTop: 0 },
                    ]}
                  >
                    Your Plan
                  </AppText>
                  <FieldArray
                    name="tasks"
                    render={(arrayHelpers) => (
                      <View>
                        {!arrayHelpers.form.values.tasks.length && (
                          <AppText>You have planned nothing so far.</AppText>
                        )}
                        {arrayHelpers.form.values.tasks.map((task, index) => {
                          return (
                            <View key={index}>
                              <AppText>* {task}</AppText>
                            </View>
                          )
                        })}
                      </View>
                    )}
                  />
                </View>

                <AppText
                  style={[defaultStyles.questionWithPadding, { marginTop: 20 }]}
                >
                  How long do you need to prepare this plan?
                </AppText>
                <FormField
                  name="prepareTime"
                  numberOfLines={1}
                  placeholder="how many days etc.."
                />
              </View>
            </FormikStep>
            <FormikStep
              validationSchema={Yup.object({
                youTime: Yup.date().required().label('When is your you time?'),
                rehearsalDate: Yup.date()
                  .required()
                  .label('When is the rehearsal?'),
                reviewDate: Yup.date().required().label('When is the review?'),
              })}
            >
              <CioTitle title="Prepare - Yourself" />
              <View style={defaultStyles.mainContainer}>
                <AppText>
                  Book time now when you can prepare yourself, practice your
                  BOSS routine, rehearse your content, and conduct a Rapid
                  Review afterwards.
                </AppText>
                <AppText style={defaultStyles.questionWithPadding}>
                  You time: (to practice your BOSS routine)
                </AppText>
                <FormDatePicker
                  name="youTime"
                  icon="calendar-month"
                  dateFormat="do MMMM yyyy h:mm aa"
                  enableTime={true}
                  placeholder="Date &amp; time picker"
                />
                <AppText style={defaultStyles.questionWithPadding}>
                  Rehearsal time
                </AppText>
                <FormDatePicker
                  name="rehearsalDate"
                  icon="calendar-month"
                  dateFormat="do MMMM yyyy h:mm aa"
                  enableTime={true}
                  placeholder="Date &amp; time picker"
                />
                <AppText style={defaultStyles.questionWithPadding}>
                  Review time
                </AppText>
                <FormDatePicker
                  name="reviewDate"
                  icon="calendar-month"
                  dateFormat="do MMMM yyyy h:mm aa"
                  enableTime={true}
                  placeholder="Date &amp; time picker"
                />
              </View>
            </FormikStep>
            <FormikStep
              validationSchema={Yup.object({
                youTimeNudgeOptions: NudgeValidationSchema(),
                rehearsalDateNudgeOptions: NudgeValidationSchema(),
                reviewDateNudgeOptions: NudgeValidationSchema(),
              })}
            >
              <CioTitle title="Prepare - Yourself" />
              <FormNudge
                fieldName="rehearsalDateNudge"
                fieldNameOption="rehearsalDateNudgeOptions"
                nudgeText="Would you like a nudge in advance of the rehearsal date?"
                placeholder="No thanks"
                formatSource="rehearsalDate"
                autoSubmit={false}
                options={[
                  {
                    label: 'Two hours before',
                    format: { num: 2, unit: 'hours', sub: true },
                  },
                  {
                    label: 'Three days before',
                    format: { num: 3, unit: 'days', sub: true },
                  },
                  {
                    label: 'A week before',
                    format: { num: 1, unit: 'weeks', sub: true },
                  },
                ]}
                nudgeDetails={{
                  title: 'Critical Impact Opportunity Rehearsal',
                  description:
                    'Nudging you about your Critical Impact Opportunity rehearsal',
                }}
              />
              <FormNudge
                fieldName="youTimeNudge"
                fieldNameOption="youTimeNudgeOptions"
                nudgeText="Would you like a nudge in advance of your you time?"
                placeholder="No thanks"
                formatSource="youTime"
                autoSubmit={false}
                options={[
                  {
                    label: 'Two hours before',
                    format: { num: 2, unit: 'hours', sub: true },
                  },
                  {
                    label: 'Three days before',
                    format: { num: 3, unit: 'days', sub: true },
                  },
                  {
                    label: 'A week before',
                    format: { num: 1, unit: 'weeks', sub: true },
                  },
                ]}
                nudgeDetails={{
                  title: 'Critical Impact Opportunity You Time',
                  description:
                    'Nudging you about your Critical Impact Opportunity you time',
                }}
              />
              <FormNudge
                fieldName="reviewDateNudge"
                fieldNameOption="reviewDateNudgeOptions"
                nudgeText="Would you like a nudge in advance of the review date?"
                placeholder="No thanks"
                formatSource="reviewDate"
                autoSubmit={false}
                options={[
                  {
                    label: 'Two hours before',
                    format: { num: 2, unit: 'hours', sub: true },
                  },
                  {
                    label: 'Three days before',
                    format: { num: 3, unit: 'days', sub: true },
                  },
                  {
                    label: 'A week before',
                    format: { num: 1, unit: 'weeks', sub: true },
                  },
                ]}
                nudgeDetails={{
                  title: 'Critical Impact Opportunity Review',
                  description:
                    'Nudging you about your Critical Impact Opportunity review',
                }}
              />
              <IsNotificationDisabled />
            </FormikStep>

            <FormikStep>
              <CioTitle title="Prepare" />
              <View style={defaultStyles.mainContainer}>
                <AppText style={defaultStyles.questionWithPadding}>
                  My BOSS Practice
                </AppText>

                <AppText>
                  Choose the most appropriate BOSS practice for your CIO:
                </AppText>
                <ActivityIndicator visible={bossApi.isLoading} />
                {bossApi.isError && (
                  <>
                    <AppText>Couldn't retrieve the BOSS practices.</AppText>
                    <AppButton
                      title="Retry"
                      onPress={() => bossApi.refetch()}
                    />
                  </>
                )}
                {bossApi.isSuccess && (
                  <FormBossChoice
                    defaultValue={data.bossId}
                    bossPractices={bossApi.data}
                    originalFormData={data}
                  />
                )}
              </View>
            </FormikStep>
            <FormikStep
              validationSchema={Yup.object({
                boss1: Yup.string().label('Body posture?'),
                boss2: Yup.string().label('Breath Practice?'),
                boss3: Yup.string().label('Self-Talk for Energy?'),
                boss4: Yup.string().label('Self-Talk for Action?'),
              })}
            >
              <CioTitle title="Perform" />
              <View style={defaultStyles.mainContainer}>
                <AppText>
                  For those pressure moments, what is your{' '}
                  <AppText style={styles.highlight}>BOSS</AppText> practice?
                </AppText>
                <BossLabel
                  letter="B"
                  circleColour={colors.blue}
                  label="Body posture"
                />
                <FormField
                  multiline
                  name="boss1"
                  numberOfLines={3}
                  placeholder="Choose the posture or movement that will prepare you best"
                />
                <BossLabel
                  letter="O"
                  circleColour={colors.blue}
                  label="Oxygen - Breath Practice"
                />
                <FormField
                  multiline
                  name="boss2"
                  numberOfLines={3}
                  placeholder="Choose the breathing technique that will best support you to perform well"
                />
                <BossLabel
                  letter="S"
                  circleColour={colors.red}
                  label="Self-Talk for Energy"
                />
                <FormField
                  multiline
                  name="boss3"
                  numberOfLines={3}
                  placeholder="Choose a mantra that increases your motivation to act – from your ambition, confidence or purpose"
                />
                <BossLabel
                  letter="S"
                  circleColour={colors.green}
                  label="Self-Talk for Action"
                />
                <FormField
                  multiline
                  name="boss4"
                  numberOfLines={3}
                  placeholder="Choose a mantra that gets you into action – however small"
                />
              </View>
            </FormikStep>
            <FormikStep>
              <CioTitle title="Perform" />
              <View style={defaultStyles.mainContainer}>
                <AppText>
                  Remember how you chose to connect with your audience DURING
                  the CIO
                </AppText>

                <View style={[defaultStyles.lightBox]}>
                  <AppText
                    style={[
                      defaultStyles.questionWithPadding,
                      { marginTop: 0, marginBottom: 20, paddingTop: 0 },
                    ]}
                  >
                    <DisplayFieldValue field="humanConnection" />
                  </AppText>
                </View>
              </View>
            </FormikStep>
          </FormikStepper>
        )}
      </View>
    </Screen>
  )
}

const styles = StyleSheet.create({
  bold: {
    fontWeight: '600',
  },
  contributors: {
    backgroundColor: defaultConfig.colors.lightPrimary,
    borderRadius: 15,
    padding: 20,
  },
  highlight: {
    color: defaultConfig.colors.primary,
  },
  horizontalAlign: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  questionContainer: {
    flex: 1,
    justifyContent: 'flex-start',
    paddingTop: 10,
    paddingBottom: 10,
    width: '100%',
    maxWidth: 768,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
})
