import React, { useState } from 'react'
import { FlatList, StyleSheet } from 'react-native'
import { useFocusEffect } from '@react-navigation/native'
import { parseISO } from 'date-fns'

import ActivityIndicator from '../../../components/ActivityIndicator'
import Button from '../../../components/Button'
import Nudgification from '../../../components/Nudgification'
import { defaultStyles } from '../../../config/styles'
import nudgificationsApi from '../../../api/nudgifications'
import Screen from '../../../components/Screen'
import AppText from '../../../components/Text'
import Title from '../../../components/Title'
import useApi from '../../../hooks/useApi'
import useAuth from '../../../auth/useAuth'

interface INudge {
  id: string
  user: string // IRI
  nudge: string
  readAt: string | null
  details: {
    title: string
    description: string
  }
  component: string
  componentId: string
  created: string
}

function NudgificationsScreen() {
  const [noNudges, setNoNudges] = useState(true)
  const { user } = useAuth()
  const getNudgificationsApi = useApi(nudgificationsApi.getNudgifications)
  const now = new Date()

  useFocusEffect(
    React.useCallback(() => {
      getNudgificationsApi
        .request(user.id)
        .then((response) => {
          for (const [k, v] of Object.entries(response.data as INudge[])) {
            console.log(v)
            const nudgeDate = parseISO(v.nudge)
            if (nudgeDate <= now) {
              setNoNudges(false)
            }
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }, [])
  )

  return (
    <Screen style={defaultStyles.screen}>
      <Title style={styles.title}>Nudgifications</Title>
      <ActivityIndicator visible={getNudgificationsApi.loading} />
      {getNudgificationsApi.error && (
        <>
          <AppText>Couldn't retrieve the nudgifications.</AppText>
          <Button title="Retry" onPress={getNudgificationsApi.request} />
        </>
      )}
      {noNudges && <AppText>There are currently no Nudgifications</AppText>}
      <FlatList
        data={getNudgificationsApi.data}
        keyExtractor={(nudgification: INudge) => {
          return nudgification.id
        }}
        renderItem={({ item }) => {
          const nudgeDate = parseISO(item.nudge)

          if (nudgeDate > now) {
            return null
          }

          return (
            <Nudgification
              title={item.details.title}
              subTitle={item.details.description}
              nudgeDate={nudgeDate}
              read={item.readAt !== null}
              id={item.id}
              userId={item.user}
              component={item.component}
              componentId={item.componentId}
            />
          )
        }}
      />
    </Screen>
  )
}

const styles = StyleSheet.create({
  title: {
    fontSize: 24,
    fontWeight: '500',
    textAlign: 'center',
  },
})

export default NudgificationsScreen
