import React, { useEffect } from 'react'
import { View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import TitleGraphic from '../../../../../components/TileGraphic'
import AppText from '../../../../../components/Text'
import InfoButton from '../../../../../components/InfoButton'
import ButtonIcon from '../../../../../components/ButtonIcon'
import colors from '../../../../../config/colors'
import { dashboardStyles } from '../../../../../config/styles'
import useAuth from '../../../../../auth/useAuth'
import { IWidget } from './IWidget'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { AppStackParamList } from '../../../../../navigation/AppNavigator'

export const CioWidgetName = 'cio'

export const CioWidget: React.FC<IWidget> = ({ editing, onLoaded }) => {
  const navigation = useNavigation<DrawerNavigationProp<AppStackParamList>>()
  useEffect(() => {
    onLoaded(CioWidgetName)
  }, [onLoaded])

  const { user } = useAuth()
  const showCio = user.features.includes('FEATURE_CIO')

  if (!showCio) {
    return null
  }

  return (
    <div style={dashboardStyles.block}>
      <TitleGraphic
        title="Critical Impact Opportunities"
        graphic={require('/assets/lead-cio.jpg')}
        offset={false}
      />

      <View style={dashboardStyles.blockBody}>
        <AppText style={dashboardStyles.blockText}>
          Moments that matter - manage your Critical Impact Opportunities here.
        </AppText>

        <InfoButton
          text="Learn more about CIOs"
          onPress={() => {
            if (editing) {
              return
            }

            navigation.navigate('RESOURCE', {
              screen: 'Resource_View',
              params: {
                slug: 'critical-impact-opportunities-cios',
              },
            })
          }}
        />
        <View style={{ flexDirection: 'row' }}>
          <View style={{ flex: 1 }}>
            <ButtonIcon
              text="New CIO"
              icon="text-box-plus-outline"
              color={colors.white}
              backgroundColor={colors.primary}
              onPress={() => {
                if (editing) {
                  return
                }

                navigation.navigate('CRITICAL_IMPACT_OPPORTUNITY', {
                  screen: 'CriticalImpactOpportunity_AddForm',
                  params: {},
                })
              }}
            />
          </View>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <View style={{ flex: 1 }}>
            <ButtonIcon
              text="Your CIOs"
              icon="text-box-search-outline"
              color={colors.primary}
              backgroundColor={colors.white}
              borderColor={colors.primary}
              onPress={() => {
                if (editing) {
                  return
                }

                navigation.navigate('CRITICAL_IMPACT_OPPORTUNITY', {
                  screen: 'CriticalImpactOpportunity_List',
                })
              }}
            />
          </View>
        </View>
      </View>
    </div>
  )
}
