import React, { useEffect } from 'react'
import { StyleSheet, View } from 'react-native'
import { useNavigation } from '@react-navigation/native'

import trophyCabinetQuery from '../../../../../api/query/trophyCabinet'
import TitleGraphic from '../../../../../components/TileGraphic'
import AppText from '../../../../../components/Text'
import { dashboardStyles, defaultStyles } from '../../../../../config/styles'
import useAuth from '../../../../../auth/useAuth'
import { IWidget } from './IWidget'
import ActivityIndicator from '../../../../../components/ActivityIndicator'
import Button from '../../../../../components/Button'
import TrophyButton from '../../../../../components/trophy/TrophyButton'
import TrophyShelf from '../../../../../components/trophy/TrophyShelf'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { AppStackParamList } from '../../../../../navigation/AppNavigator'

export const TrophyCabinentWidgetName = 'trophyCabinent'

export const TrophyCabinentWidget: React.FC<IWidget> = ({
  editing,
  onLoaded,
}) => {
  const navigation = useNavigation<DrawerNavigationProp<AppStackParamList>>()
  const { user } = useAuth()
  const { isLoading, isSuccess, isError, data, refetch } =
    trophyCabinetQuery.getTrophies(user.id)

  useEffect(() => {
    onLoaded(TrophyCabinentWidgetName)
  }, [onLoaded, isSuccess])

  return (
    <div style={dashboardStyles.block}>
      <TitleGraphic
        title="My Trophy Cabinet"
        graphic={require('/assets/lead-trophy.jpg')}
        offset={false}
      />

      <ActivityIndicator visible={isLoading} />
      <View style={[dashboardStyles.blockBody]}>
        {isError && (
          <>
            <AppText>Couldn't retrieve your Trophy Cabinet.</AppText>
            <Button title="Retry" onPress={() => refetch()} />
          </>
        )}
        {isSuccess && data.length > 0 && (
          <View style={styles.shelfStyle}>
            {data.map((trophy) => {
              return (
                <TrophyButton
                  key={trophy.id}
                  onPress={() => {
                    if (editing) {
                      return
                    }

                    navigation.navigate('TROPHY_CABINET', {
                      screen: 'Trophy_View',
                      params: {
                        trophyId: trophy.id,
                      },
                    })
                  }}
                >
                  <TrophyShelf trophy={trophy} />
                </TrophyButton>
              )
            })}
          </View>
        )}
        {isSuccess && data.length === 0 && (
          <AppText>There are currently no Trophies in your Cabinet.</AppText>
        )}
      </View>
    </div>
  )
}

const styles = StyleSheet.create({
  shelfStyle: {
    alignContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    width: '100%',
  },
})
