import React from 'react'
import { FlatList, View } from 'react-native'

import { defaultStyles } from '../../../config/styles'
import ActivityIndicator from '../../../components/ActivityIndicator'
import resourceQuery from '../../../api/query/resource'
import Screen from '../../../components/Screen'
import Button from '../../../components/Button'
import Title from '../../../components/Title'
import AppText from '../../../components/Text'
import useAuth from '../../../auth/useAuth'
import ResourceButton from '../../../components/screen-button/ResourceButton'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { AppStackParamList } from '../../../navigation/AppNavigator'
import { useNavigation } from '@react-navigation/native'

function ListScreen() {
  const navigation = useNavigation<DrawerNavigationProp<AppStackParamList>>()
  const { user } = useAuth()

  const { isLoading, isSuccess, isError, data, refetch } =
    resourceQuery.getResources()

  return (
    <Screen style={defaultStyles.screen}>
      <Title style={{ fontSize: 25, color: 'black' }}>
        HPP Refresher Animations
      </Title>
      <View style={defaultStyles.mainContainer}>
        <ActivityIndicator visible={isLoading} />
        {isError && (
          <>
            <AppText>Couldn't retrieve the animations.</AppText>
            <Button title="Retry" onPress={() => refetch()} />
          </>
        )}
        {isSuccess && (
          <FlatList
            data={data}
            keyExtractor={(resource) => {
              return resource.id.toString()
            }}
            renderItem={({ item }) => (
              <ResourceButton
                resource={item}
                onPress={() => {
                  navigation.navigate('RESOURCE', {
                    screen: 'Resource_View',
                    params: {
                      slug: item.slug,
                    },
                  })
                }}
              />
            )}
          />
        )}
      </View>
    </Screen>
  )
}

export default ListScreen
