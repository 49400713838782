import React from 'react'
import { useState } from 'react'
import { StyleSheet, Text } from 'react-native'

import { defaultStyles } from '../../config/styles'
import TextInput from '../TextInput'
import ToggleButton from '../ToggleButton'
import useAuth from '../../auth/useAuth'

function Profile({ onChange }) {
  const { user } = useAuth()
  const rituals = user.rituals
  const firstTime = null === rituals
  const options = [
    'Feeling rested',
    'Hydration',
    'Exercise',
    'Diet',
    'Fresh air',
    'Calm breathing',
    'Quiet',
    'Social interaction',
    'Music',
  ]
  const [localRituals, setLocalRituals] = useState(
    rituals.filter((element) => options.includes(element))
  )

  const foundOther = rituals.filter((element) => !options.includes(element))
  const [other, setOther] = useState(
    foundOther ? foundOther[foundOther.length - 1] : undefined
  )

  function returnRituals() {
    if (onChange) {
      const ritualWithOther = localRituals.filter((element) =>
        options.includes(element)
      )

      if (other) {
        ritualWithOther.push(other)
      }

      onChange(ritualWithOther)
    }
  }

  return (
    <>
      {firstTime && (
        <>
          <Text style={[defaultStyles.text]}>
            As this is the first time you've logged in we need to build your HPP
            profile.
          </Text>

          <hr style={{ width: '100%' }} />
        </>
      )}
      <Text style={[defaultStyles.text]}>
        What makes a positive difference to the way you perform?
      </Text>
      <Text style={{ marginTop: 10, marginBottom: 20 }}>
        (tick all that apply)
      </Text>

      {options.map((option, index) => {
        return (
          <ToggleButton
            key={index}
            title={option}
            initialEnabled={localRituals.includes(option)}
            onChange={(enabled) => {
              const buttonRituals = localRituals
              if (enabled) {
                buttonRituals.push(option)
              } else {
                const index = buttonRituals.indexOf(option)

                if (index !== -1) {
                  buttonRituals.splice(index, 1)
                }
              }

              setLocalRituals(buttonRituals)
              returnRituals()
            }}
          />
        )
      })}

      <Text style={[defaultStyles.text, styles.heading, { width: '100%' }]}>
        Other
      </Text>
      <TextInput
        onChangeText={(text) => {
          setOther(text)
          returnRituals()
        }}
        onBlur={() => {
          returnRituals()
        }}
        value={other}
      />
    </>
  )
}

export default Profile

const styles = StyleSheet.create({
  heading: {
    fontWeight: '600',
    minWidth: 150,
    marginBottom: 10,
    marginTop: 20,
  },
})
