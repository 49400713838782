import React from 'react'
import { View } from 'react-native'

import Screen from '../../../components/Screen'
import useAuth from '../../../auth/useAuth'
import criticalImpactOpportunityQuery from '../../../api/query/criticalImpactOpportunity'

import { defaultStyles } from '../../../config/styles'
import TitleGraphic from '../../../components/TitleGraphic'
import Intro from '../../../components/cio/Intro'
import Main from '../../../components/cio/Main'
import { ICio } from 'types/cio'
import AppText from '../../../components/Text'
import ActivityIndicator from '../../../components/ActivityIndicator'
import AppButton from '../../../components/Button'
import { Sections } from '../../../utility/cioSection'

export default function FormScreen({ route }) {
  const cioId: string | null =
    route?.params?.criticalImpactOpportunityId ?? null
  const { isLoading, isSuccess, isError, data, refetch } =
    criticalImpactOpportunityQuery.getCriticalImpactOpportunity(cioId)
  const { user } = useAuth()

  return (
    <Screen style={defaultStyles.screen}>
      <TitleGraphic
        section="Critical Impact Opportunities"
        title="Create a CIO"
        graphic={require('../../../assets/lead-cio.jpg')}
      />
      <View style={defaultStyles.mainContainer}>
        {isLoading && <ActivityIndicator visible={isLoading} />}
        {isError && (
          <>
            <AppText>Couldn't retrieve the CIO.</AppText>
            <AppButton title="Retry" onPress={() => refetch()} />
          </>
        )}
        {isSuccess && <Section data={data} />}
      </View>
    </Screen>
  )
}

interface ISection {
  data: ICio
}

function Section({ data }: Readonly<ISection>) {
  if (data.section < Sections.intro) {
    return (
      <Intro
        next={() => {
          data.section = Sections.main
        }}
      />
    )
  }

  return <Main cioId={data.id ?? null} nextSection={data.section + 1} />
}
