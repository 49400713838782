import React from 'react'
import { FlatList, StyleSheet, View } from 'react-native'
import { format, parseISO } from 'date-fns'

import ActivityIndicator from '../../../components/ActivityIndicator'
import Screen from '../../../components/Screen'
import Button from '../../../components/Button'
import AppText from '../../../components/Text'
import { defaultStyles } from '../../../config/styles'
import useAuth from '../../../auth/useAuth'
import CriticalImpactOpportunityButton from '../../../components/screen-button/CriticalImpactOpportunityButton'
import TitleGraphic from '../../../components/TitleGraphic'
import cioQuery from '../../../api/query/criticalImpactOpportunity'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { AppStackParamList } from '../../../navigation/AppNavigator'
import { useNavigation } from '@react-navigation/native'

function ListArchiveScreen() {
  const navigation = useNavigation<DrawerNavigationProp<AppStackParamList>>()
  const { user } = useAuth()
  const { isLoading, isSuccess, isError, data, refetch } =
    cioQuery.getArchivedCriticalImpactOpportunities(user.id)

  return (
    <Screen style={defaultStyles.screen}>
      <TitleGraphic
        section="Critical Impact Opportunities"
        title="Your CIOs"
        graphic={require('../../../assets/lead-cio.jpg')}
      />

      <View style={defaultStyles.mainContainer}>
        <AppText style={styles.text}>Your Archived CIOs</AppText>

        <ActivityIndicator visible={isLoading} />
        {isError && (
          <>
            <AppText>
              Couldn't retrieve the archived Critical Impact Opportunities.
            </AppText>
            <Button title="Retry" onPress={() => refetch()} />
          </>
        )}
        {isSuccess && (
          <FlatList
            data={data}
            keyExtractor={(criticalImpactOpportunity) => {
              return criticalImpactOpportunity.id.toString()
            }}
            renderItem={({ item }) => (
              <CriticalImpactOpportunityButton
                date={format(parseISO(item.date), 'do MMMM yyyy')}
                meetingName={item.what}
                id={item.id}
                onPress={() => {
                  navigation.navigate('CRITICAL_IMPACT_OPPORTUNITY', {
                    screen: 'CriticalImpactOpportunity_Menu',
                    params: {
                      criticalImpactOpportunityId: item.id,
                    },
                  })
                }}
              />
            )}
            ListEmptyComponent={
              <AppText>There are currently no archived CIOs</AppText>
            }
          />
        )}
      </View>
    </Screen>
  )
}

const styles = StyleSheet.create({
  text: {
    fontSize: 25,
    fontWeight: '600',
    marginVertical: 20,
  },
})

export default ListArchiveScreen
