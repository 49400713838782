import React, { useState } from 'react'
import RitualScreen from '../../../screens/app/Profile/RitualScreen'
import StartupModal from './StartupModal'
import useAuth from '../../../auth/useAuth'

function RitualModal() {
  const [showRituals, setShowRituals] = useState(true)
  const { user } = useAuth()

  if (!user || user.rituals.length > 0) {
    return null
  }

  return (
    <StartupModal modalVisible={showRituals} setModalVisible={setShowRituals}>
      <RitualScreen
        rituals={user.rituals}
        navigate={false}
        onClose={() => {
          setShowRituals(false)
        }}
      />
    </StartupModal>
  )
}

export default RitualModal
