import React from 'react'
import { FlatList, View } from 'react-native'

import ActivityIndicator from '../../../components/ActivityIndicator'
import bossQuery from '../../../api/query/boss'
import Screen from '../../../components/Screen'
import Button from '../../../components/Button'
import AppText from '../../../components/Text'
import colors from '../../../config/colors'
import { defaultConfig, defaultStyles } from '../../../config/styles'
import ButtonIcon from '../../../components/ButtonIcon'
import InfoButton from '../../../components/InfoButton'
import useAuth from '../../../auth/useAuth'
import BossButton from '../../../components/screen-button/BossButton'
import TitleGraphic from '../../../components/TitleGraphic'
import { useNavigation } from '@react-navigation/native'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { AppStackParamList } from '../../../navigation/AppNavigator'

function ListScreen() {
  const navigation = useNavigation<DrawerNavigationProp<AppStackParamList>>()

  const { user } = useAuth()
  const { isLoading, isSuccess, isError, data, refetch } = bossQuery.getBosses(
    user.id
  )

  return (
    <Screen style={defaultStyles.screen}>
      <TitleGraphic
        section="Boss"
        title="My BOSS Practice"
        graphic={require('../../../assets/lead-boss.jpg')}
      />
      <View style={defaultStyles.mainContainer}>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            marginBottom: 10,
          }}
        >
          <InfoButton
            text="Learn more about BOSS"
            onPress={() => {
              navigation.navigate('RESOURCE', {
                screen: 'Resource_View',
                params: {
                  slug: 'bossing-your-brains',
                },
              })
            }}
          />
        </View>
        <ButtonIcon
          text="Create a BOSS Practice"
          icon="text-box-plus-outline"
          color={colors.white}
          backgroundColor={colors.primary}
          onPress={() => {
            navigation.navigate('BOSS', {
              screen: 'Boss_AddForm',
            })
          }}
        />
        <ActivityIndicator visible={isLoading} />
        {isError && (
          <>
            <AppText>Couldn't retrieve the BOSS practices.</AppText>
            <Button title="Retry" onPress={() => refetch()} />
          </>
        )}
        {isSuccess && (
          <FlatList
            style={{ marginTop: 15 }}
            data={data}
            keyExtractor={(boss) => {
              return boss.id.toString()
            }}
            renderItem={({ item }) => (
              <BossButton
                buttonBackground={defaultConfig.colors.lightGrey}
                highlighted={false}
                bossName={item.name}
                onPress={() => {
                  navigation.navigate('BOSS', {
                    screen: 'Boss_View',
                    params: {
                      bossId: item.id,
                    },
                  })
                }}
              />
            )}
            ListEmptyComponent={
              <AppText>There are currently no BOSS Practices</AppText>
            }
          />
        )}
      </View>
    </Screen>
  )
}

export default ListScreen
