import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { dashboardReducer } from './slices/dashboard'

const rootReducer = combineReducers({
  dashboard: dashboardReducer,
})

export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})
