import { FlatList, View } from 'react-native'
import { useNavigation } from '@react-navigation/native'

import ActivityIndicator from '../../../../../components/ActivityIndicator'
import bossQuery from '../../../../../api/query/boss'
import TitleGraphic from '../../../../../components/TileGraphic'
import AppText from '../../../../../components/Text'
import Button from '../../../../../components/Button'
import { dashboardStyles, defaultConfig } from '../../../../../config/styles'
import { IWidget } from './IWidget'
import BossButton from '../../../../../components/screen-button/BossButton'
import useAuth from '../../../../../auth/useAuth'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { AppStackParamList } from '../../../../../navigation/AppNavigator'
import { useEffect } from 'react'

export const BossPracticeWidgetName = 'bossPractice'

export const BossPracticeWidget: React.FC<IWidget> = ({
  editing,
  onLoaded,
}) => {
  const { user } = useAuth()
  const navigation = useNavigation<DrawerNavigationProp<AppStackParamList>>()
  const { isLoading, isSuccess, isError, data, refetch } =
    bossQuery.getDashboardBosses(user.id)

  useEffect(() => {
    onLoaded(BossPracticeWidgetName)
  }, [onLoaded, isSuccess])

  return (
    <div style={dashboardStyles.block}>
      <TitleGraphic
        title="My BOSS Practice"
        graphic={require('/assets/lead-boss.jpg')}
        offset={false}
      />

      <ActivityIndicator visible={isLoading} />

      <View style={dashboardStyles.blockBody}>
        {isError && (
          <>
            <AppText>Couldn't retrieve the BOSS practices.</AppText>
            <Button title="Retry" onPress={() => refetch()} />
          </>
        )}
        {isSuccess && (
          <FlatList
            style={{ marginTop: 15 }}
            data={data}
            keyExtractor={(boss) => {
              return boss.id.toString()
            }}
            renderItem={({ item }) => (
              <BossButton
                bossName={item.name}
                highlighted={false}
                buttonBackground={defaultConfig.colors.white}
                onPress={() => {
                  if (editing) {
                    return
                  }

                  navigation.navigate('BOSS', {
                    screen: 'Boss_View',
                    params: {
                      bossId: item.id,
                    },
                  })
                }}
              />
            )}
            ListEmptyComponent={
              <AppText>There are currently no BOSS Practices</AppText>
            }
          />
        )}
      </View>
    </div>
  )
}
