import React from 'react'
import { StyleSheet, View } from 'react-native'
import * as Yup from 'yup'

import { Form, FormField, SubmitButton } from '../../../components/forms'

import { defaultStyles } from '../../../config/styles'
import Screen from '../../../components/Screen'
import Title from '../../../components/Title'
import ActivityIndicator from '../../../components/ActivityIndicator'
import useAuth from '../../../auth/useAuth'
import userQuery from '../../../api/query/user'
import AppText from '../../../components/Text'
import AppButton from '../../../components/Button'
import { useMutation, useQueryClient } from 'react-query'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { AppStackParamList } from '../../../navigation/AppNavigator'
import { useNavigation } from '@react-navigation/native'

const validationSchema = Yup.object().shape({
  preRitual: Yup.string().label('Pre-CIO ritual'),
})

function PreRitualScreen() {
  const navigation = useNavigation<DrawerNavigationProp<AppStackParamList>>()
  const { user } = useAuth()
  const queryClient = useQueryClient()
  const { isSuccess, isError, isLoading, data, refetch } = userQuery.getUser(
    user.id
  )

  const preRitualMutation = useMutation(userQuery.updatePreRitual, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['user', { userId: data.id }])
      navigation.navigate('PROFILE', {
        screen: 'Profile',
      })
    },
    onError: (error) => {
      alert('Failed to update your pre-ritual')
      console.log(error)
    },
  })

  const handleSubmit = async ({ preRitual }) => {
    preRitualMutation.mutate({
      userId: user.id,
      preRitual: preRitual,
    })
  }

  return (
    <Screen style={defaultStyles.screen}>
      <View style={[defaultStyles.mainContainer, styles.mainContainer]}>
        <ActivityIndicator visible={isLoading} />
        {isError && (
          <>
            <AppText>Couldn't retrieve your pre-ritual.</AppText>
            <AppButton title="Retry" onPress={refetch()} />
          </>
        )}
        {isSuccess && (
          <>
            <Title style={{ fontSize: 25, color: 'black' }}>
              Your pre-CIO ritual
            </Title>

            <AppText>
              What helps you get into the right frame of mind before a CIO?
            </AppText>
            <Form
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
              initialValues={{
                preRitual: data.preRitual,
              }}
              enableReinitialize={true}
            >
              <FormField
                multiline
                name="preRitual"
                numberOfLines={3}
                placeholder="Your pre-CIO ritual"
              />

              <SubmitButton title="Save" />
            </Form>
          </>
        )}
      </View>
    </Screen>
  )
}

const styles = StyleSheet.create({
  mainContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    fontSize: 25,
    fontWeight: '600',
    marginVertical: 20,
  },
})

export default PreRitualScreen
