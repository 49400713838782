import React, { useState } from 'react'
import { StyleSheet, View } from 'react-native'

import teamQuery from '../../api/query/team'
import userQuery from '../../api/query/user'
import useAuth from '../../auth/useAuth'
import colors from '../../config/colors'
import ActivityIndicator from '../ActivityIndicator'
import AppButton from '../Button'
import AppText from '../Text'
import ButtonIcon from '../ButtonIcon'
import { Picker } from './Picker'

type ItemGroup = {
  id: number
  name: string
}

type Item = {
  label?: string
  value: string | number
  group?: ItemGroup
}

export interface IFormOnChangeContactPicker {
  id: string | null
  name: string
  type?: string
}

interface IContactPicker {
  name: string
  group: boolean
  onValueChange?(item: IFormOnChangeContactPicker): void
  onAdd?(member): void
  placeholder: string
  showAdd?: boolean
  showTeams?: boolean
  showOther?: boolean
  width: any
}

function ContactPicker({
  name,
  group = false,
  onValueChange,
  onAdd,
  placeholder,
  showAdd = true,
  showTeams = false,
  showOther = false,
  width,
}: IContactPicker) {
  const { user } = useAuth()
  const [currentSelected, setCurrentSelected] = useState<string | null>(null)
  const teamApi = teamQuery.getTeams(user.id)
  const userApi = userQuery.getCompanyUsers()
  const userGroups: ItemGroup[] = []

  if (userApi.isLoading || teamApi.isLoading) {
    return (
      <ActivityIndicator visible={userApi.isLoading || teamApi.isLoading} />
    )
  }

  if (userApi.isError) {
    return (
      <>
        <AppText>Couldn't retrieve a list of your collegues.</AppText>
        <AppButton title="Retry" onPress={() => userApi.refetch()} />
      </>
    )
  }

  if (teamApi.isError) {
    return (
      <>
        <AppText>Couldn't retrieve a list of your teams.</AppText>
        <AppButton title="Retry" onPress={() => teamApi.refetch()} />
      </>
    )
  }

  if (!userApi.isSuccess || !teamApi.isSuccess) {
    return null
  }

  if (group) {
    userApi.data.map((item: Item) => {
      if (
        item.group !== undefined &&
        userGroups.findIndex((userGroup) => {
          return userGroup.id === item.group?.id
        }) === -1
      ) {
        userGroups.push(item.group)
      }
    })
  }

  return (
    <View>
      <View style={styles.buttonRow}>
        <Picker
          onValueChange={(item) => {
            item = String(item)
            setCurrentSelected(item)

            if (!item && onValueChange) {
              onValueChange({ id: null, name: '', type: '' })
              return
            }

            const id = item.substring(item.length - 36)
            let found

            if (item === 'other') {
              found = {
                name: 'other',
                type: 'email',
              }
            } else if (item.startsWith('/team')) {
              found = teamApi.data.find((team) => team.id === id)

              if (found) {
                found.type = 'team'
              }
            } else {
              found = userApi.data.find((user) => user.id === id)

              if (found) {
                found.type = 'user'
              }
            }

            if (!onValueChange) {
              return
            }

            if (!found) {
              onValueChange({
                id: null,
                name: '',
                type: '',
              })
              return
            }

            onValueChange(found)
          }}
          placeholder={placeholder}
          selectedValue={currentSelected ?? ''}
          width={width}
          style={styles.picker}
        >
          {placeholder !== '' && <Picker.Item label={placeholder} value={''} />}
          {showTeams && (
            <optgroup key={'teams'} label={'Teams'}>
              {teamApi.data.map((item, index) => {
                return (
                  <Picker.Item
                    key={'team_' + index.id}
                    label={item.name}
                    value={buildUrl('team', item.id)}
                  />
                )
              })}
            </optgroup>
          )}
          {!group &&
            userApi.data.map((item, index) => {
              return (
                <Picker.Item
                  key={'user_' + index.id}
                  label={item.name}
                  value={buildUrl('user', item.id)}
                />
              )
            })}
          {group &&
            userGroups.map((userGroup) => {
              const groupItems: JSX.Element[] = []

              userApi.data.map((item, index) => {
                if (item.group.id !== userGroup.id) {
                  return
                }

                groupItems.push(
                  <Picker.Item
                    key={'user_' + index.id}
                    label={item.name}
                    value={buildUrl('user', item.id)}
                  />
                )
              })

              return (
                <optgroup key={userGroup.id} label={userGroup.name}>
                  {groupItems}
                </optgroup>
              )
            })}
          {showOther && (
            <Picker.Item label="Other" value="other" key={'email_other'} />
          )}
        </Picker>
        {showAdd && (
          <ButtonIcon
            icon="plus"
            color={colors.white}
            backgroundColor={colors.primary}
            onPress={onAdd}
            text="Add"
          />
        )}
      </View>
    </View>
  )
}

export default ContactPicker

function buildUrl(type: string, id: string) {
  let prefix = ''

  if (type === 'user') {
    prefix = '/users/'
  } else if (type === 'team') {
    prefix = '/teams/'
  }

  return prefix + id
}

const styles = StyleSheet.create({
  buttonRow: {
    flexDirection: 'row',
  },
  heading: {
    fontWeight: '600',
    minWidth: 150,
    marginBottom: 10,
    marginTop: 20,
  },
  picker: {
    borderColor: '#6e6969',
    borderRadius: 10,
    borderStyle: 'solid',
    borderWidth: 1,
    fontSize: 16,
    fontFamily: 'Open Sans',
    marginTop: 10,
    marginRight: 20,
    padding: 10,
    width: '100%',
  },
})
