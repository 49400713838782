import React from 'react'
import { FlatList, StyleSheet, View } from 'react-native'

import ActivityIndicator from '../../../../components/ActivityIndicator'
import Screen from '../../../../components/Screen'
import Button from '../../../../components/Button'
import AppText from '../../../../components/Text'
import colors from '../../../../config/colors'
import { defaultStyles } from '../../../../config/styles'
import ButtonIcon from '../../../../components/ButtonIcon'
import InfoButton from '../../../../components/InfoButton'
import useAuth from '../../../../auth/useAuth'
import rrQuery from '../../../../api/query/rapidReview'
import RapidReviewButton from '../../../../components/screen-button/RapidReviewButton'
import { format, parseISO } from 'date-fns'
import TitleGraphic from '../../../../components/TitleGraphic'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { AppStackParamList } from '../../../../navigation/AppNavigator'
import { useNavigation } from '@react-navigation/native'

function ListScreen() {
  const navigation = useNavigation<DrawerNavigationProp<AppStackParamList>>()
  const { user } = useAuth()
  const { isLoading, isSuccess, isError, data, refetch } =
    rrQuery.getRapidReviews(user.id)

  return (
    <Screen style={defaultStyles.screen}>
      <TitleGraphic
        section="Rapid Reviews"
        title="Your Rapid Reviews"
        graphic={require('../../../../assets/lead-rapid-review.jpg')}
      />
      <View style={defaultStyles.mainContainer}>
        <AppText style={styles.IntroText}>
          The Practice of reviewing is key to learning faster and improvement.
          Whether it is the review of a meeting, a presentation, a 1-to-1 or
          even of your own performance, the practice of pausing to reflect on
          what was useful and what would have made the interaction more useful,
          enables you to learn faster and incorporate improvements as you go
          forward.
        </AppText>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            marginBottom: 10,
          }}
        >
          <InfoButton
            text="Learn more about Rapid Reviews"
            onPress={() => {
              navigation.navigate('RESOURCE', {
                screen: 'Resource_View',
                params: {
                  slug: 'rapid-review',
                },
              })
            }}
          />
        </View>
        <ButtonIcon
          text="Create a Rapid Review"
          icon="text-box-plus-outline"
          color={colors.white}
          backgroundColor={colors.primary}
          onPress={() => {
            navigation.navigate('RAPID_REVIEW', {
              screen: 'RapidReview_Form',
              params: {},
            })
          }}
        />
        <ActivityIndicator visible={isLoading} />
        {isError && (
          <>
            <AppText>Couldn't retrieve the rapid reviews.</AppText>
            <Button title="Retry" onPress={() => refetch()} />
          </>
        )}
        {isSuccess && (
          <FlatList
            style={{ marginTop: 15 }}
            data={data}
            keyExtractor={(rapidreview) => {
              return rapidreview.id.toString()
            }}
            renderItem={({ item }) => (
              <RapidReviewButton
                date={format(parseISO(item.date), 'do MMMM yyyy')}
                meetingName={item.meetingName}
                id={item.id}
                onPress={() => {
                  navigation.navigate('RAPID_REVIEW', {
                    screen: 'RapidReview_View',
                    params: {
                      rapidReviewId: item.id,
                    },
                  })
                }}
              />
            )}
            ListEmptyComponent={
              <AppText>There are currently no Rapid Reviews</AppText>
            }
          />
        )}
      </View>
    </Screen>
  )
}

const styles = StyleSheet.create({
  text: {
    fontSize: 25,
    fontWeight: '600',
    marginVertical: 20,
  },
  IntroText: {
    marginBottom: 20,
  },
})

export default ListScreen
