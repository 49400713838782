import React from 'react'
import { Platform, View } from 'react-native'
import { Formik } from 'formik'
import * as Yup from 'yup'

import { defaultStyles } from '../../../../config/styles'
import Screen from '../../../../components/Screen'
import AppText from '../../../../components/Text'
import useAuth from '../../../../auth/useAuth'
import InfoButton from '../../../../components/InfoButton'
import joinMeeting from '../../../../utility/joinMeeting'
import useApi from '../../../../hooks/useApi'
import meetingApi from '../../../../api/meeting'
import { FormField, SubmitButton } from '../../../../components/forms'
import TitleGraphic from '../../../../components/TitleGraphic'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { AppStackParamList } from '../../../../navigation/AppNavigator'
import { useNavigation } from '@react-navigation/native'

function JoinScreen() {
  const navigation = useNavigation<DrawerNavigationProp<AppStackParamList>>()
  const { user } = useAuth()
  const joinMeetingApi = useApi(meetingApi.joinMeeting)

  const handleSubmit = async (values, actions) => {
    joinMeeting(user, joinMeetingApi, values.meetingCode, (response) => {
      if (!response.ok) {
        actions.setFieldError(
          'meetingCode',
          'Team rapid review not found or already ended.'
        )

        return
      }

      navigation.navigate('RAPID_REVIEW', {
        screen:
          response.data.startDate !== null
            ? 'Meeting_View_InProgress'
            : 'Meeting_View_Upcoming',
        params: {
          meetingId: response.data.id,
        },
      })
    })
  }

  return (
    <Screen style={defaultStyles.screen}>
      <TitleGraphic
        section="Team Rapid Review"
        title="Join a team rapid review"
        graphic={require('../../../../assets/lead-rapid-review.jpg')}
      />
      <View style={defaultStyles.mainContainer}>
        <Formik
          initialValues={{ meetingCode: '' }}
          onSubmit={handleSubmit}
          validationSchema={Yup.object({
            meetingCode: Yup.string().required().min(1).label('Meeting code'),
          })}
        >
          {(formikProps) => (
            <>
              <AppText style={{ fontSize: 25, color: 'black' }}>
                Enter team code
              </AppText>
              <FormField
                width="100%"
                style={{
                  outlineStyle: 'none',
                  fontSize: 16,
                  fontFamily: 'Open Sans',
                  width: '100%',
                }}
                name="meetingCode"
                placeholder="Enter code here"
              />

              <SubmitButton title="Join team rapid review" />

              {Platform.OS !== 'web' && (
                <InfoButton
                  text="Scan meeting QR code"
                  showIcon={false}
                  onPress={() => {
                    navigation.navigate('RAPID_REVIEW', {
                      screen: 'Meeting_Camera',
                    })
                  }}
                />
              )}
            </>
          )}
        </Formik>
      </View>
    </Screen>
  )
}

export default JoinScreen
