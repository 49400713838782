import React, { useEffect } from 'react'
import WelcomeScreen from '../../../screens/app/Startup/WelcomeScreen'
import StartupModal from './StartupModal'
import appStateQuery from '../../../api/query/appState'
import { useMutation, useQueryClient } from 'react-query'
import useAuth from '../../../auth/useAuth'
import { STATE_KEY } from '../../../config/constants'
import { QUERY_KEYS } from '../../../config/queryKeys'

interface IWelcomeMessageModal {
  onFinish: () => void
}

function WelcomeMessageModal({ onFinish }: IWelcomeMessageModal) {
  const queryClient = useQueryClient()
  const { user } = useAuth()

  const welcomeMessageState = appStateQuery.getAppState(
    STATE_KEY.hasSeenWelcomeMessage
  )

  const stateMutation = useMutation(appStateQuery.postAppState, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.appState.all)
      queryClient.invalidateQueries([
        QUERY_KEYS.appState.item(STATE_KEY.hasSeenWelcomeMessage),
      ])
      onFinish()
    },
    onError: (error) => {
      alert('Failed to update state')
      console.log(error)
    },
  })

  const hideModal =
    !user.features.includes('FEATURE_WELCOME_MESSAGE') ||
    !welcomeMessageState.isSuccess ||
    welcomeMessageState.data.stateValue === true

  useEffect(() => {
    if (hideModal && !welcomeMessageState.isLoading) {
      onFinish()
    }
  }, [hideModal, welcomeMessageState.isLoading])

  if (hideModal) {
    return null
  }

  function changeState(stateValue: boolean) {
    stateMutation.mutate({
      stateKey: STATE_KEY.hasSeenWelcomeMessage,
      stateValue: stateValue,
    })
  }

  return (
    <StartupModal
      modalVisible={welcomeMessageState.data.stateValue !== true}
      setModalVisible={changeState}
    >
      <WelcomeScreen
        onClose={() => {
          changeState(true)
        }}
      />
    </StartupModal>
  )
}

export default WelcomeMessageModal
